import React from 'react';
import { connect } from 'react-redux';

class UIError extends React.Component {
	render() {
		if(!this.props.message) return(<div className="ui-error" />);
		
		if(!isNaN(this.props.message))
			return(<div className="ui-error mt-3">{this.props.errors[this.props.message]}</div>);

		return(<div className="ui-error">{this.props.message}</div>);
	}
}

function mapStateToProps(state) {
	return {
		errors: state.ui.language.errors,
	}
}

export default connect(mapStateToProps)(UIError);
