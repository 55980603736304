import AppConstants from '../constants/app-constants.js';
//import AppUtils from './../utils/app-utils.js';
import AppActions from '../actions/app-actions.js';

//import Cookies from 'universal-cookie';
import AppWebsocket from '../utils/app-websocket.js';

const WebsocketService = store => next => action => {
	//const cookie = new Cookies();

	/* Pass all actions through by default */
	next(action)

	//let dataserialized = new FormData();
	//let ticket = cookie.get("ticket");
	//if(typeof ticket !== 'undefined' && ticket.length > 0) {
		//dataserialized.append("ticket", ticket);
	//}

	switch (action.type) {
		case AppConstants.WEBSOCKET_ONLINE:
			//if(ticket && ticket.length > 0 && !AppWebsocket.isAuthed()) {
			//	AppWebsocket.sendMessage(AppActions.WebsocketLoginWithTicket(ticket));
			//}
			//AppWebsocket.sendMessage(AppActions.MWebsocketSettingsLoad());
			break;

		case AppConstants.CREATE_LOBBY:
			AppWebsocket.sendMessage(AppActions.MWebsocketCreateLobby(action.data));
			break;
		case AppConstants.JOIN_LOBBY:
			AppWebsocket.sendMessage(AppActions.MWebsocketJoinLobby(action.data));
			break;
		case AppConstants.LEAVE_LOBBY:
			AppWebsocket.sendMessage(AppActions.MWebsocketLeaveLobby(action.data));
			break;
		case AppConstants.KICK:
			AppWebsocket.sendMessage(AppActions.MWebsocketKick(action.data));
			break;
		case AppConstants.LEAVE_LOBBY_SILENT:
			AppWebsocket.sendMessage(AppActions.MWebsocketLeaveLobbySilent(action.data));
			break;
		case AppConstants.GAME_START:
			AppWebsocket.sendMessage(AppActions.MWebsocketGameStart(action.data));
			break;
		case AppConstants.GAME_ANSWER:
			AppWebsocket.sendMessage(AppActions.MWebsocketGameAnswer(action.data));
			break;
		case AppConstants.READY:
			AppWebsocket.sendMessage(AppActions.MWebsocketReady(action.data));
			break;
		case AppConstants.PING:
			AppWebsocket.sendMessage(AppActions.MWebsocketPong(action.data));
			break;
		case AppConstants.COOKIE:
			AppWebsocket.sendMessage(AppActions.MWebsocketCookie(action.data));
			break;
		case AppConstants.COOKIE_VALIDATE:
			AppWebsocket.sendMessage(AppActions.MWebsocketCookieValidate(action.data));
			break;
		case AppConstants.REMATCH_LOBBY:
			AppWebsocket.sendMessage(AppActions.MWebsocketRematchLobby(action.data));
			break;
		case AppConstants.SPEAKER_NEXT:
			AppWebsocket.sendMessage(AppActions.MWebsocketSpeakerNext(action.data));
			break;
		/* Do nothing if the action does not interest us */
		default:
			break;
	}
};

export default WebsocketService;
