import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import Store from './../stores/app-store';

import AppWebsocket from '../utils/app-websocket';

import Index from './app-index';
import Play from './app-play';
import Create from './app-create';
import Join from './app-join';
import Lobby from './app-lobby';
import Help from './app-help';
import Speaker from './app-speaker';

//import AppLobbyWheel from './app-lobby-wheel';

import NotFound from './app-404';

import ServerStatus from './app-server-status';

import AppStore from '../stores/app-store';
import AppActions from '../actions/app-actions';

class App extends React.Component {
	componentDidMount() {
		AppWebsocket.onConnect();
		if("language" in navigator && typeof navigator.language === 'string') {
			let x = navigator.language.split('-');
			AppStore.dispatch(AppActions.LanguageSet({language: x[0]}));
		}
	}

	render() {
		return(
			<Provider store={Store}>
				<div className="top-header-server-status">
					<ServerStatus />
				</div>

				<Switch>
					<Route exact path="/" component={Index} />
					
					<Route exact path="/play" component={Play} />
					<Route exact path="/create/:mode" component={Create} />
					<Route exact path="/create" component={Create} />

					<Route path="/join/:code" component={Join} />
					<Route exact path="/join" component={Join} />

					<Route exact path="/speaker" component={Speaker} />
					<Route path="/lobby/:code" component={Lobby} />

					<Route path="/help" component={Help} />

					{/*<Route path="/test" component={AppLobbyWheel} />*/}
					
					<Route component={NotFound} />
				</Switch>
			</Provider>
		);
	}
}

export default withRouter(App);
