import React from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';


import AppActions from '../actions/app-actions.js';
import AppStore from '../stores/app-store.js';
import AppUtils from '../utils/app-utils';
import AppConstants from '../constants/app-constants';

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

class Ongoing extends React.Component {
	constructor(props) {
		super(props);

		this.validateCookies = this.validateCookies.bind(this);
		this.onLeaveSilent = this.onLeaveSilent.bind(this);
	}
	componentDidMount() {
		if(this.props.ui.websocketState !== AppConstants.STATE_WEBSOCKET_ONLINE) return;
		this.validateCookies();
	}
	componentDidUpdate(prevProps, prevState, ss) {
		if(prevProps.ui.websocketState !== AppConstants.STATE_WEBSOCKET_ONLINE && this.props.ui.websocketState === AppConstants.STATE_WEBSOCKET_ONLINE) {
			this.validateCookies();
		}
	}

	validateCookies() {
		AppStore.dispatch(AppActions.CookieValidateClear({}));

		AppUtils.log("[Ongoing]", AppUtils.getAllCookies());
		let x = AppUtils.getAllCookies();
		for(var a in x) {
			if(a.substring(0, 5) === "MOES_") {
				let lby = a.substring(5);
				let cde = x[a];
				AppUtils.log("Lobby", lby, "has cookie", cde);
				AppStore.dispatch(AppActions.CookieValidate({lobby: lby, code: cde}));
			}
		}
	}

	onLeaveSilent(v) {
		AppUtils.log("[Silent] Leaving lobby", v.lobby);
		let c = AppUtils.getCookie(v.lobby);
		if(c !== undefined) {
			AppUtils.log("[Silent] We also have a cookie", c);
			AppStore.dispatch(AppActions.LeaveLobbySilent({code: v.lobby, cookie: c}));
		}
	}

	render() {
		/* Don't show ongoing popup if we're offline */
		if(this.props.ui.websocketState !== AppConstants.STATE_WEBSOCKET_ONLINE) return(<React.Fragment></React.Fragment>);

		let showPopup = false;
		let ongoing = [];
		if(this.props.ui.ongoingGames.length > 0) {
			showPopup = true;
			this.props.ui.ongoingGames.forEach( (v, i) => {
				//AppUtils.log("[Ongoing Game]", v.lobby, "on question #", v.question, "with", v.players, "players.");
				ongoing.push(
				<div key={i}>
					<div className="content text-center">
						<b className="lh-08 highlight big-text game-code">{v.lobby}</b>
						<br />
						({v.players} players)
					</div>
					<div className="actions text-center row">
						<div className="col-5"><button className="btn btn-custom btn-custom-width" onClick={() => this.onLeaveSilent(v)}>Leave</button></div>
						<div className="col-2">&nbsp;</div>
						<div className="col-5"><Link to={"/lobby/"+v.lobby} className="btn btn-custom btn-custom-width">Join</Link></div>
					</div>
					<div className="row">&nbsp;</div>
				</div>);
			});
		}
		return(
			<Popup open={showPopup} closeOnDocumentClick={false} modal contentStyle={{backgroundColor: "#6fa7c5", width: "unset", border: "1px solid #0f486b"}}>
				<div className="container">
					<div className="header text-center">
						<h2>{this.props.language.ongoing}</h2>
						<b>{this.props.language.info}</b>
					</div>
					<div className="row">&nbsp;</div>
					{ongoing}
				</div>
			</Popup>
		);
	}
}
function mapStateToProps(state) {
	return {
		ui: state.ui,
		language: state.ui.language.ongoing,
	}
}
export default connect(mapStateToProps)(Ongoing);
