import AppConstants from '../constants/app-constants.js';
//import Cookies from 'universal-cookie';

/*
 * 'data' is used by middlewares to manipulate data
 * 'payload' is used by the store (data is used in special occasions)
*/
var AppActions = {

	/* Websocket (for react) */
	WebsocketOnline: function(data) {
		return ({
			type: AppConstants.WEBSOCKET_ONLINE,
			data: data,
		});
	},
	WebsocketState: function(data) {
		return ({
			type: AppConstants.WEBSOCKET_STATE,
			payload: data
		});
	},
	WebsocketSubscribe: function(data) {
		return({
			type: AppConstants.SUBSCRIBE,
			data: data,
		});
	},
	WebsocketUnsubscribe: function() {
		return({
			type: AppConstants.UNSUBSCRIBE,
			data: {
			},
		});
	},

	/* Websocket messages (for moes-ws, different layout) */
	MWebsocketSettingsLoad: function() {
		return({
			event: AppConstants.SETTINGS_LOAD
		});
	},
	MWebsocketCreateLobby: function(d) {
		return({
			event: AppConstants.CREATE_LOBBY,
			data: d
		});
	},
	MWebsocketJoinLobby: function(d) {
		return({
			event: AppConstants.JOIN_LOBBY,
			data: d
		});
	},
	MWebsocketLeaveLobby: function(d) {
		return({
			event: AppConstants.LEAVE_LOBBY,
			data: d
		});
	},
	MWebsocketKick: function(d) {
		return({
			event: AppConstants.KICK,
			data: d
		});
	},
	MWebsocketLeaveLobbySilent: function(d) {
		return({
			event: AppConstants.LEAVE_LOBBY_SILENT,
			data: d
		});
	},
	MWebsocketGameStart: function(d) {
		return({
			event: AppConstants.GAME_START,
			data: d
		});
	},
	MWebsocketGameAnswer: function(d) {
		return({
			event: AppConstants.GAME_ANSWER,
			data: d
		});
	},
	MWebsocketPing: function(d) {
		return({
			event: AppConstants.PING,
			data: d
		});
	},
	MWebsocketCookie: function(d) {
		return({
			event: AppConstants.COOKIE,
			data: d
		});
	},
	MWebsocketCookieValidate: function(d) {
		return({
			event: AppConstants.COOKIE_VALIDATE,
			data: d
		});
	},
	MWebsocketRematchLobby: function(d) {
		return({
			event: AppConstants.REMATCH_LOBBY,
			data: d
		});
	},
	MWebsocketReady: function(d) {
		return({
			event: AppConstants.READY,
			data: d
		});
	},
	MWebsocketPong: function(d) {
		return({
			event: AppConstants.PONG,
			data: d
		});
	},
	MWebsocketSpeakerNext: function(d) {
		return({
			event: AppConstants.SPEAKER_NEXT,
			data: d
		});
	},

	/* Settings */
	SettingsLoad: function(data) {
		return ({
			type: AppConstants.SETTINGS_LOAD,
			data: data
		});
	},
	SettingsLoadSuccess: function(data) {
		return ({
			type: AppConstants.SETTINGS_LOAD_SUCCESS,
			payload: data,
		});
	},
	SettingsLoadFailure: function(data) {
		return ({
			type: AppConstants.SETTINGS_LOAD_FAILURE,
			error: data
		});
	},
	SpeakerNext: function(data) {
		return({
			type: AppConstants.SPEAKER_NEXT,
			data: data
		});
	},
	/* Lobby stuff */
	CreateLobby: function(d) {
		return ({
			type: AppConstants.CREATE_LOBBY,
			data: d
		});
	},
	CreateLobbySuccess: function(d) {
		return ({
			type: AppConstants.CREATE_LOBBY_SUCCESS,
			payload: d
		});
	},
	CreateLobbyFailure: function(d) {
		return ({
			type: AppConstants.CREATE_LOBBY_FAILURE,
			error: d
		});
	},
	CreateLobbyReset: function(d) {
		return ({
			type: AppConstants.CREATE_LOBBY_RESET,
			error: d
		});
	},
	JoinLobby: function(d) {
		return ({
			type: AppConstants.JOIN_LOBBY,
			data: d
		});
	},
	JoinLobbySuccess: function(d) {
		return ({
			type: AppConstants.JOIN_LOBBY_SUCCESS,
			payload: d
		});
	},
	JoinLobbyFailure: function(d) {
		return ({
			type: AppConstants.JOIN_LOBBY_FAILURE,
			error: d
		});
	},
	JoinLobbyReset: function(d) {
		return ({
			type: AppConstants.JOIN_LOBBY_RESET,
			error: d
		});
	},
	Kick: function(d) {
		return ({
			type: AppConstants.KICK,
			data: d
		});
	},
	KickSuccess: function(d) {
		return ({
			type: AppConstants.KICK,
			payload: d
		});
	},
	KickFailure: function(d) {
		return ({
			type: AppConstants.KICK,
			error: d
		});
	},
	LobbyKicked: function(d) {
		return ({
			type: AppConstants.LOBBY_KICKED,
			payload: d
		});
	},
	LeaveLobby: function(d) {
		return ({
			type: AppConstants.LEAVE_LOBBY,
			data: d
		});
	},
	LeaveLobbySuccess: function(d) {
		return ({
			type: AppConstants.LEAVE_LOBBY_SUCCESS,
			payload: d
		});
	},
	LeaveLobbyFailure: function(d) {
		return ({
			type: AppConstants.LEAVE_LOBBY_FAILURE,
			error: d
		});
	},
	LeaveLobbySilent: function(d) {
		return ({
			type: AppConstants.LEAVE_LOBBY_SILENT,
			data: d
		});
	},
	LeaveLobbySilentSuccess: function(d) {
		return ({
			type: AppConstants.LEAVE_LOBBY_SILENT_SUCCESS,
			payload: d
		});
	},
	LeaveLobbySilentFailure: function(d) {
		return ({
			type: AppConstants.LEAVE_LOBBY_SILENT_FAILURE,
			payload: { code: d.code },
			error: d.error
		});
	},
	RematchLobby: function(d) {
		return ({
			type: AppConstants.REMATCH_LOBBY,
			data: d
		});
	},
	RematchLobbySuccess: function(d) {
		return ({
			type: AppConstants.REMATCH_LOBBY_SUCCESS,
			payload: d
		});
	},
	RematchLobbyFailure: function(d) {
		return ({
			type: AppConstants.REMATCH_LOBBY_FAILURE,
			error: d
		});
	},

	LobbyState: function(d) {
		return ({
			type: AppConstants.LOBBY_STATE,
			payload: d
		});
	},
	LobbyUpdate: function(d) {
		return ({
			type: AppConstants.LOBBY_UPDATE,
			payload: d
		});
	},
	LobbyError: function(d) {
		return ({
			type: AppConstants.LOBBY_ERROR,
			error: d
		});
	},
	LobbyPlayer: function(d) {
		return ({
			type: AppConstants.LOBBY_PLAYER,
			payload: d
		});
	},
	LobbyPlayerStatus: function(d) {
		return ({
			type: AppConstants.LOBBY_PLAYER_STATUS,
			payload: d
		});
	},
	LobbyCookie: function(d) {
		return({
			type: AppConstants.LOBBY_COOKIE,
			payload: d
		});
	},
	LobbyAdmin: function(d) {
		return({
			type: AppConstants.LOBBY_ADMIN,
			payload: d
		});
	},
	LobbyTimeleft: function(d) {
		return({
			type: AppConstants.LOBBY_TIMELEFT,
			payload: d
		});
	},

	/* Game stuff */
	GameStart: function(d) {
		return ({
			type: AppConstants.GAME_START,
			data: d
		});
	},
	GameStartFailure: function(d) {
		return ({
			type: AppConstants.GAME_START_FAILURE,
			error: d
		});
	},
	GameResetAnswer: function(d) {
		return ({
			type: AppConstants.GAME_RESET_ANSWER,
			payload: d
		});
	},
	GameAnswer: function(d) {
		return ({
			type: AppConstants.GAME_ANSWER,
			data: d
		});
	},
	GameAnswerSuccess: function(d) {
		return ({
			type: AppConstants.GAME_ANSWER_SUCCESS,
			payload: d
		});
	},
	GameAnswerFailure: function(d) {
		return ({
			type: AppConstants.GAME_ANSWER_FAILURE,
			error: d
		});
	},
	ShowQuestion: function(d) {
		return ({
			type: AppConstants.SHOW_QUESTION,
			payload: d
		});
	},
	Ready: function(d) {
		return ({
			type: AppConstants.READY,
			data: d
		});
	},
	ReadySuccess: function(d) {
		return ({
			type: AppConstants.READY_SUCCESS,
			payload: d
		});
	},
	ReadyFailure: function(d) {
		return ({
			type: AppConstants.READY_FAILURE,
			error: d
		});
	},

	Ping: function(d) {
		return({
			type: AppConstants.PING,
			data: d
		});
	},
	Pong: function(d) {
		return({
			type: AppConstants.PONG,
			data: d
		});
	},

	/* UI */
	LanguageSet: function(data) {
		return ({
			type: AppConstants.LANGUAGE_SET,
			payload: data,
		});
	},
	AnnouncementAdd: function(data) {
		return ({
			type: AppConstants.ANNOUNCEMENT_ADD,
			payload: data
		});
	},
	AnnouncementClose: function(data) {
		return ({
			type: AppConstants.ANNOUNCEMENT_CLOSE,
			payload: data
		});
	},

	Cookie: function(d) {
		return({
			type: AppConstants.COOKIE,
			data: d
		});
	},
	CookieSuccess: function(d) {
		return({
			type: AppConstants.COOKIE_SUCCESS,
			payload: d
		});
	},
	CookieFailure: function(d) {
		return({
			type: AppConstants.COOKIE_FAILURE,
			error: d
		});
	},
	CookieFailureForget: function(d) {
		return({
			type: AppConstants.COOKIE_FAILURE_FORGET,
			payload: { code: d.code },
			error: d.error
		});
	},
	CookieValidate: function(d) {
		return({
			type: AppConstants.COOKIE_VALIDATE,
			data: d
		});
	},
	CookieValidateSuccess: function(d) {
		return({
			type: AppConstants.COOKIE_VALIDATE_SUCCESS,
			payload: d
		});
	},
	CookieValidateFailure: function(d) {
		return({
			type: AppConstants.COOKIE_VALIDATE_FAILURE,
			payload: { code: d.code },
			error: d.error
		});
	},
	CookieValidateClear: function(d) {
		return({
			type: AppConstants.COOKIE_VALIDATE_CLEAR,
			data: d
		});
	},
}
export default AppActions;
