import React from 'react'

import AppUtils from '../utils/app-utils';
import UITimer from './app-ui-timer';

import { connect } from 'react-redux';

class LobbyAdvertise extends React.Component {

	componentDidUpdate(prevProps, prevState, ss) {
		if(this.props.lobby.state !== prevProps.lobby.state) {
			this.setState({resetTimer: true}, function() {
				this.setState({resetTimer: false})
			});
		}
	}

	componentDidMount() {
		AppUtils.setBackgroundImage();
		AppUtils.vibrate(500);
	}
	componentWillUnmount() {
		AppUtils.setBackgroundLinear();
	}
	
	render() {
		let ad = "";
		switch(this.props.lobby.advertise.type.toLowerCase()) {
			case "image":
				ad = (<img src={this.props.lobby.advertise.url} alt="Advertisement" className="img-fluid" />);
				break;
			default:
				ad = "Failed to load";
		}
		
		return(<div style={{minHeight: '100%', display: 'flex', alignItems: 'center'}}>
			<div className="container text-center my-auto">
				<UITimer seconds={this.props.lobby.timeleft} reset={false} />
				<div className="row">&nbsp;</div>
				<div className="col-lg-12 col-sm-12 mb-3">
					<h3 className="lh-11 mt-3 responsive-text-big">{this.props.lobby.advertise.title}</h3>
				</div>
				<div className="row justify-content-center mx-auto">
					{ad}
				</div>
			</div>
		</div>
		);

	}
}

function mapStateToProps(state) {
	return {
		language: state.ui.language.lobby.drink,
		lobby: state.page.lobby
	}
}

export default connect(mapStateToProps)(LobbyAdvertise);
