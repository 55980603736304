import React from 'react'
import AppActions from '../actions/app-actions.js';
import AppStore from '../stores/app-store.js';

import { Link } from 'react-router-dom';
import UIError from './app-ui-error';

import logoIndexImage from '../images/logo.svg';

import Ongoing from './app-ongoing';

import { connect } from 'react-redux';

class Create extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			nickname: "",
			code: "",
		};

		this.onJoin = this.onJoin.bind(this);
		this.onChange = this.onChange.bind(this);
	}

	componentDidUpdate(prevProps, prevState, ss) {
		if(prevProps.join.code !== this.props.join.code && this.props.join.code) {
			this.props.history.push("/lobby/"+this.props.join.code);
		}
	}

	componentDidMount() {
		AppStore.dispatch(AppActions.JoinLobbyReset());
		if(this.props.match.params.code && this.props.match.params.code.length > 0)
			this.setState({code: this.props.match.params.code });
	}

	onJoin() {
		AppStore.dispatch(AppActions.JoinLobby(this.state));
	}
	onChange(name, e) {
		if(name === "nickname") this.setState({ nickname: e.target.value.trim() });
		else if(name === "code") this.setState({ code: e.target.value.trim() });
	}

	render() {
		return(
			<div className="container-fluid col-12 text-center d-flex justify-content-center h-index">
				<Ongoing />
				<div className="logo-container"><img src={logoIndexImage} alt="Logo" className="img-fluid logo mb-2" /></div>
				<div className="col-xl-4 col-lg-5 col-md-7 col-sm-9 col-11 my-auto box box-shadow box-padding">
					<div className="t-l">
						<Link to="/play"><i className="fas fa-arrow-left"></i> {this.props.language.back.toUpperCase()}</Link>
					</div>
					<div className="row justify-content-sm-center">
						<div className="col-lg-8 col-sm-8">
						
							<div className="form-group">
									<label htmlFor="lobby_code"><h3 className="lh-08 text-center">{this.props.language.lobbyCode.toUpperCase()}</h3></label>
									<input type="number" className="form-control text-center" defaultValue={this.props.match.params.code} id="lobby_code" autoComplete="off" onChange={(e) => this.onChange("code", e)} />
							</div>
							<div className="form-group">
									<label htmlFor="nickname"><h3 className="lh-08 text-center">{this.props.language.yourName.toUpperCase()}</h3></label>
									<input type="text" className="form-control text-center" id="nickname" autoComplete="off" onChange={(e) => this.onChange("nickname", e)} />
							</div>
							<br />
							<button className="btn btn-custom btn-custom-height" type="button" onClick={this.onJoin} disabled={this.props.join.isLoading}>
								{this.props.language.joinLobby}
							</button>
							<UIError message={this.props.join.error} />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		join: state.page.join,
		language: state.ui.language.join,
		images: state.ui.language.images
	}
}

export default connect(mapStateToProps)(Create);
