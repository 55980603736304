import Sockette from 'sockette';

import AppActions from '../actions/app-actions.js';
import AppStore from '../stores/app-store.js';
import AppConstants from '../constants/app-constants.js';
import AppUtils from '../utils/app-utils';

class AppWebsocket {
	constructor() {
		this.state = { connected: false, idle: false, lastRead: 0, lastWrite: 0, pingHandle: undefined };

		this.onOpen = this.onOpen.bind(this);
		this.onClose = this.onClose.bind(this);
		this.onError = this.onError.bind(this);

		this.onConnect = this.onConnect.bind(this);
		this.onReconnect = this.onReconnect.bind(this);
		this.onMaximum = this.onMaximum.bind(this);

		this.onMessage = this.onMessage.bind(this);
		this.sendMessage = this.sendMessage.bind(this);

		this.pingTimer = this.pingTimer.bind(this);
		this.onDisconnect = this.onDisconnect.bind(this);
		
		this.buffer = [];

		//AppStore.dispatch(AppActions.WebsocketState({ state: AppConstants.STATE_WEBSOCKET_CONNECTING }));
		/*this.socket = new Sockette(process.env.REACT_APP_WEBSOCKET, {
			timeout:		5000,
			maxAttempts:		process.env.REACT_APP_WEBSOCKET_ATTEMPTS,
			protocols:		['moes-main-ws'],
			onopen:			this.onOpen,
			onmessage:		this.onMessage,
			onreconnect:		this.onReconnect,
			onmaximum:		this.onMaximum,
			onclose:		this.onClose,
			onerror:		this.onError,
		});*/
	}

	pingTimer() {
		let t = Math.floor(Date.now() / 1000);
		
		let lr = t-this.state.lastRead;
		let lw = t-this.state.lastWrite;
		
		if(lr >= 10 && lw >= 10) {
			this.sendMessage(AppActions.MWebsocketPing({}));
		}
	}
	onDisconnect() {
		this.state.lastWrite = 0;
		this.state.lastRead = 0;
		if(this.state.pingHandle) {
			clearInterval(this.state.pingHandle);
			this.state.pingHandle = undefined;
		}
	}

	onConnect() {
		AppStore.dispatch(AppActions.WebsocketState({ state: AppConstants.STATE_WEBSOCKET_CONNECTING }));
		this.socket = new Sockette(process.env.REACT_APP_WEBSOCKET, {
			timeout:		2000,
			maxAttempts:	process.env.REACT_APP_WEBSOCKET_ATTEMPTS,
			protocols:		['moes-main-ws'],
			onopen:			this.onOpen,
			onmessage:		this.onMessage,
			onreconnect:	this.onReconnect,
			onmaximum:		this.onMaximum,
			onclose:		this.onClose,
			onerror:		this.onError,
		});
	}

	onOpen(e) {
		AppUtils.log("[websocket] Open", e);

		this.state.connected = true;
		this.state.lastRead = 0;
		this.state.lastWrite = 0;

		AppStore.dispatch(AppActions.WebsocketState({ state: AppConstants.STATE_WEBSOCKET_ONLINE }));
		if(this.state.pingHandle) clearInterval(this.state.pingHandle);
		this.state.pingHandle = setInterval(this.pingTimer, 10000);

		/* Send buffer */
		if (this.buffer.length > 0) {
			this.buffer.forEach( out => {
				this.sendMessage(out);
			});
			this.buffer = [];
		}
	}
	onClose(e) {
		AppUtils.log("[websocket] Close", e);

		this.state.connected = false;
		
		this.onDisconnect();

		if(!this.state.idle)
			AppStore.dispatch(AppActions.WebsocketState({ state: AppConstants.STATE_WEBSOCKET_OFFLINE }))

	}
	onError(e) {
		AppUtils.log("[websocket] Error", e);

		this.state.connected = false;
		this.onDisconnect();

		AppStore.dispatch(AppActions.WebsocketState({ state: AppConstants.STATE_WEBSOCKET_OFFLINE }))
	}

	onReconnect(e) {
		AppUtils.log("[websocket] Reconnecting..");
		AppStore.dispatch(AppActions.WebsocketState({ state: AppConstants.STATE_WEBSOCKET_CONNECTING, lastError: 0 }))
		this.state.idle = false;
	}
	onMaximum(e) {
		AppUtils.log("[websocket] Reached max reconnects of", process.env.REACT_APP_WEBSOCKET_ATTEMPTS);
		if(this.state.idle) return;
		
		this.state.idle = true;
		AppStore.dispatch(AppActions.WebsocketState({ state: AppConstants.STATE_WEBSOCKET_IDLE }))
		//AppStore.dispatch(AppActions.AnnouncementClose({ id: AppConstants.WEBSOCKET_ERROR }));
		//AppStore.dispatch(AppActions.AnnouncementAdd({ id: AppConstants.WEBSOCKET_ERROR, message: 'Unable to connect to backend. Refresh this page to try and reconnect again.' }));
	}

	onMessage(e) {
		this.state.lastRead = Math.floor(Date.now() / 1000);
		
		AppUtils.log("[websocket] Message", e);

		let tmp = e.data.split(/\r?\n/);

		tmp.forEach( d => {
			let data;
			try {
				data = JSON.parse(d);
			} catch(e) {
				return;
			}

			switch(data.event) {
				case AppConstants.SETTINGS_LOAD:
					if(data.error) AppStore.dispatch(AppActions.SettingsLoadFailure(data.error));
					else AppStore.dispatch(AppActions.SettingsLoadSuccess(data.data));
					break;
				case AppConstants.JOIN_LOBBY:
					if(data.error) AppStore.dispatch(AppActions.JoinLobbyFailure(data.error));
					else AppStore.dispatch(AppActions.JoinLobbySuccess(data.data));
					break;
				case AppConstants.LEAVE_LOBBY:
					if(data.error) AppStore.dispatch(AppActions.LeaveLobbyFailure(data.error));
					else AppStore.dispatch(AppActions.LeaveLobbySuccess(data.data));
					break;
				case AppConstants.CREATE_LOBBY:
					if(data.error) AppStore.dispatch(AppActions.CreateLobbyFailure(data.error));
					else {
						AppStore.dispatch(AppActions.CreateLobbySuccess(data.data));
					}
					break;
				case AppConstants.LOBBY_STATE:
					AppStore.dispatch(AppActions.LobbyState(data.data));
					break;
				case AppConstants.LOBBY_PLAYER:
					AppStore.dispatch(AppActions.LobbyPlayer(data.data));
					break;
				case AppConstants.LOBBY_PLAYER_STATUS:
					AppStore.dispatch(AppActions.LobbyPlayerStatus(data.data));
					break;
				case AppConstants.LOBBY_TIMELEFT:
					AppStore.dispatch(AppActions.LobbyTimeleft(data.data));
					break;
				case AppConstants.LOBBY_UPDATE:
					AppStore.dispatch(AppActions.LobbyUpdate(data.data));
					break;
				case AppConstants.GAME_START:
					if(data.error) AppStore.dispatch(AppActions.GameStartFailure(data.error));
					break;
				case AppConstants.GAME_ANSWER:
					if(data.error) AppStore.dispatch(AppActions.GameAnswerFailure(data.error));
					else AppStore.dispatch(AppActions.GameAnswerSuccess(data.data));
					break;
				case AppConstants.COOKIE:
					if(data.error) {
						if(data.data && data.data.code) AppStore.dispatch(AppActions.CookieFailureForget({ code: data.data.code, error: data.error}));
						else AppStore.dispatch(AppActions.CookieFailure(data.error));
					}
					//else AppStore.dispatch(AppActions.CookieSuccess(data.data));
					break;
				case AppConstants.LOBBY_KICKED:
					AppStore.dispatch(AppActions.LobbyKicked(data.data));
					break;
				case AppConstants.COOKIE_VALIDATE:
					if(data.error) {
						if(data.data && data.data.code) AppStore.dispatch(AppActions.CookieValidateFailure({code: data.data.code, error: data.error}));
					} else {
						AppStore.dispatch(AppActions.CookieValidateSuccess(data.data));
					}
					break;
				case AppConstants.LEAVE_LOBBY_SILENT:
					if(data.error) {
						AppStore.dispatch(AppActions.LeaveLobbySilentFailure({code: data.data.code, error: data.error}));
					} else {
						AppStore.dispatch(AppActions.LeaveLobbySilentSuccess(data.data));
					}
					break;
				case AppConstants.READY:
					if(data.error) {
						AppStore.dispatch(AppActions.ReadyFailure(data.error));
					} else {
						AppStore.dispatch(AppActions.ReadySuccess(data.data));
					}
					break;
				case AppConstants.LOBBY_COOKIE:
					AppStore.dispatch(AppActions.LobbyCookie(data.data));
					break;
				case AppConstants.LOBBY_ADMIN:
					AppStore.dispatch(AppActions.LobbyAdmin(data.data));
					break;
				case AppConstants.REMATCH_LOBBY:
					if(data.error) {
						AppStore.dispatch(AppActions.RematchLobbyFailure({error: data.error}));
					} else {
						AppStore.dispatch(AppActions.RematchLobbySuccess(data.data));
					}
					break;
				case AppConstants.PING:
					AppStore.dispatch(AppActions.Ping({}));
					break;
				case AppConstants.DISCONNECT:
					AppUtils.log("[websocket] We have been disconnected with message:", data.error);
					/* Set idle */
					this.state.idle = true;
					/* Close socket */
					this.socket.close();
					/* Tell our store that we're disconnected */
					AppStore.dispatch(AppActions.WebsocketState({ state: AppConstants.STATE_WEBSOCKET_DISCONNECTED, lastError: data.error }))
					/* Close timers */
					this.onDisconnect();
					break;
				case AppConstants.PONG:
					/* Do nothing, useless message to check if pipe is still open */
					break;
				default:
					AppUtils.log("[websocket] No hit! event="+data.event+" error="+data.error+" data=", data.data);
					break;
			}
		});
	}
	sendMessage(out) {
		if(this.state.connected) {
			this.state.lastWrite = Math.floor(Date.now() / 1000);
			AppUtils.log("[websocket] Sending", out);
			this.socket.json(out);
		} else {
			/* TODO: If we're not connected, return the correct out.eventFailure() instantly. */
			let offline_msg = AppConstants.ERR_BACKEND_OFFLINE;
			switch(out.event) {
				case AppConstants.CREATE_LOBBY:
					AppStore.dispatch(AppActions.CreateLobbyFailure(offline_msg));
					break;
				case AppConstants.JOIN_LOBBY:
					AppStore.dispatch(AppActions.JoinLobbyFailure(offline_msg));
					break;
				case AppConstants.GAME_START:
					AppStore.dispatch(AppActions.GameStartFailure(offline_msg));
					break;
				default:
					AppUtils.log("[websocket] Invalid Offline event", out.event);
					break;
			}
		}
	}
};

export default new AppWebsocket();
