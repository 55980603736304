import { createStore, applyMiddleware, combineReducers } from 'redux';
import AppConstants from '../constants/app-constants.js';
import WebsocketService from '../middlewares/app-middleware.js';
import AppUtils from '../utils/app-utils';
import Cookies from 'universal-cookie';

import English from '../languages/english';
import Swedish from '../languages/swedish';

var CustomAnnouncement = 0;

const initialAuth = {
	login: {
		isLoading: false,
		error: null,

		loggedIn: false,
	},

	user: {
		id: 0,
		email: '',
		nickname: '',
	},
};

const initialUi = {
	announcements: [],
	ongoingGames: [],
	websocketState: AppConstants.STATE_WEBSOCKET_IDLE,
	websocketLastError: 0,
	language: English,
	languageAvailable: [English, Swedish]
};
const initialLobby = {
	isLoading: false,
	error: null,

	code: '',

	nickname: '',
	players: [],
	state: AppConstants.STATE_DESYNC,
	timeleft: 0,

	number_questions: 0,
	question: 0,
	answer: 0,

	ready: 0,
	ready_status: '',

	categories: [],
	chugs: 0,

	playing: 0,
	admin: 0,

	show_question: true,
	current_question: {
		category: 0,
		question: '',
		options: [],
	},
	drink: {
		question_extra: '',
		text: '',
		correct_option: '',
		correct_option_id: 0,
		correct: [],
		wrong: [],
		timeout: [],
	},
	streak: {
		players: []
	},
	advertise: {
		type: '',
		url: '',
	},
	done: {
		players: []
	},
};
const initialSpeaker = {
	isLoading: false,
	error: null,

	code: '',
	current: 0,
	questions: []
};
const initialPage = {
	join: {
		isLoading: false,
		error: null,

		code: '',
		nickname: '',
	},
	create: {
		isLoading: false,
		error: null,
		success: false,
		mode: 0,
		code: '',
	},
	lobby: initialLobby,
	speaker: initialSpeaker,
};

const initialSettings = {
	modes: [
		{ id: 1, name: "Standard", image: "https://cdn.moes.beer/images/buttons/normal.png", info: "Standard game mode" },
		{ id: 2, name: "Speaker", image: "https://cdn.moes.beer/images/buttons/speaker.png", info: "Speaker mode" },
	],
	categories: [
		{ id: 1, },
		{ id: 4, },
		{ id: 5, },
		{ id: 2, },
	],
	category_modes: [
		{ category: 1, mode: 1 },
		{ category: 2, mode: 1 },
		{ category: 4, mode: 1 },
		{ category: 5, mode: 1 },
		{ category: 4, mode: 2 },
		{ category: 5, mode: 2 },
	],
	languages: [
		{ id: 1, language: "English", image: "https://cdn.moes.beer/images/languages/english.svg" }
	],
	chugs: [
		{ name: "1", amount: 1 },
		{ name: "2", amount: 2 },
		{ name: "3", amount: 3 },
		{ name: "5", amount: 5 },
	],
};
const initialState = {
	auth: initialAuth,
	ui: initialUi,
	page: initialPage,
	settings: initialSettings,
};

function auth_dispatcher(state = initialAuth, action) {
	const cookies = new Cookies();

	AppUtils.log("[auth_dispatcher]", action, state);
	let data = action.payload;

	/* If our ticket failed, we should reset the entire page? */
	if (typeof data !== 'undefined' && typeof data.ticket !== 'undefined' && typeof data.ticket.error !== 'undefined') {
		AppUtils.log("[data.ticket.error] "+data.ticket.error);
		cookies.remove("ticket", { path: '/' });
		AppUtils.log("[data.ticket.error] Cookie removed, now: "+cookies.get("ticket"));
		return Object.assign({}, initialAuth, {
			announcements: [
				{ id: -1, message: 'Your client ticket is invalid. Please login again to refresh it.' }
			]
		});
	}

	switch(action.type) {
		default:
			return state;
	}
}

function page_dispatcher(state = initialPage, action) {
	let data = action.payload;

	AppUtils.log("[page_dispatcher]", action, state);

	switch(action.type) {
		case AppConstants.CREATE_LOBBY:
			return Object.assign({}, state, {
				create: {
					...state.create,
					isLoading: true,
					error: null,
					success: false,
					mode: 0,
					code: ''
				}
			});
		case AppConstants.CREATE_LOBBY_SUCCESS:
			if(data.mode === AppConstants.GAME_MODE_SPEAKER) {
				return Object.assign({}, state, {
					create: {
						...state.create,
						isLoading: false,
						error: null,
						success: true,
						mode: data.mode,
						code: data.code
					},
					speaker: {
						...state.speaker,
						questions: data.questions,
						code: data.code
					},
					lobby: {
						...state.lobby,
						error: null,
					}
				});
			}
			return Object.assign({}, state, {
				create: {
					...state.create,
					isLoading: false,
					error: null,
					success: true,
					mode: data.mode,
					code: data.code
				},
				lobby: {
					...state.lobby,
					error: null,
				}
			});
		case AppConstants.CREATE_LOBBY_FAILURE:
			return Object.assign({}, state, {
				create: {
					...state.create,
					isLoading: false,
					error: action.error,
					success: false,
					mode: 0,
					code: ''
				}
			});
		case AppConstants.CREATE_LOBBY_RESET:
			return Object.assign({}, state, {
				create: {
					...state.create,
					isLoading: false,
					error: null,
					code: '',
				},
				lobby: {
					...state.lobby,
					error: null,
				}
			});
		case AppConstants.REMATCH_LOBBY_SUCCESS:
			/* Do nothing, we'll get a LOBBY_UPDATE in a second or two. */
			return state;
		case AppConstants.GAME_START:
			return Object.assign({}, state, {
				lobby: {
					...state.lobby,
					error: null
				}
			});
		case AppConstants.GAME_START_FAILURE:
			return Object.assign({}, state, {
				lobby: {
					...state.lobby,
					error: action.error
				}
			});
		case AppConstants.SHOW_QUESTION:
			return Object.assign({}, state, {
				lobby: {
					...state.lobby,
					...data
				}
			});
		case AppConstants.JOIN_LOBBY:
			return Object.assign({}, state, {
				join: {
					...state.join,
					isLoading: true,
					error: null,
				}
			});
		case AppConstants.JOIN_LOBBY_SUCCESS:
			return Object.assign({}, state, {
				join: {
					...state.join,
					isLoading: false,
					error: null,
					...data
				},
				lobby: {
					...state.lobby,
					error: null,
				}
			});
		case AppConstants.JOIN_LOBBY_FAILURE:
			return Object.assign({}, state, {
				join: {
					...state.join,
					isLoading: false,
					error: action.error,
				}
			});
		case AppConstants.LOBBY_KICKED:
			if(state.lobby.code === data.code) {
				return Object.assign({}, state, {
					lobby: {
						...state.lobby,
						error: AppConstants.ERR_LOBBY_KICKED
					},
				});
			}
			return state;
		case AppConstants.LEAVE_LOBBY_SUCCESS:
			AppUtils.removeCookie(data.code);
			return Object.assign({}, state, {
				lobby: initialLobby,
				join: {
					...state.join,
					code: '',
				},
			});
		case AppConstants.JOIN_LOBBY_RESET:
			return Object.assign({}, state, {
				join: {
					...state.join,
					isLoading: false,
					error: null,
					code: ''
				}
			});
		case AppConstants.LOBBY_STATE:
			return Object.assign({}, state, {
				lobby: {
					...state.lobby,
					...data
				}
			});
		case AppConstants.LOBBY_UPDATE:
			return Object.assign({}, state, {
				lobby: {
					error: null,
					...state.lobby,
					...data
				}
			});
		case AppConstants.LOBBY_ERROR:
			return Object.assign({}, state, {
				lobby: {
					...state.lobby,
					error: action.error
				}
			});
		case AppConstants.LOBBY_PLAYER:
			if(data.add) {
				return Object.assign({}, state, {
					lobby: {
						...state.lobby,
						players: [
							...state.lobby.players,
							{ nickname: data.nickname, online: data.online }
						]
					}
				});
			}
			return Object.assign({}, state, {
				lobby: {
					...state.lobby,
					players: state.lobby.players.filter((a) => {
						return (a.nickname !== data.nickname);
					})
				}
			});
		case AppConstants.LOBBY_ADMIN:
			return Object.assign({}, state, {
				lobby: {
					...state.lobby,
					admin: data.admin
				}
			});
		case AppConstants.LOBBY_TIMELEFT:
			if(data.state === state.lobby.state) {
				return Object.assign({}, state, {
					lobby: {
						...state.lobby,
						timeleft: data.timeleft
					}
				});
			}
			return state;
		case AppConstants.GAME_RESET_ANSWER:
			return Object.assign({}, state, {
				lobby: {
					...state.lobby,
					answer: 0,
					ready: 0,
					ready_status: ''
				}
			});
		case AppConstants.READY_SUCCESS:
			return Object.assign({}, state, {
				lobby: {
					...state.lobby,
					...data
				}
			});
		case AppConstants.GAME_ANSWER_SUCCESS:
			return Object.assign({}, state, {
				lobby: {
					...state.lobby,
					...data
				}
			});
		case AppConstants.COOKIE_FAILURE:
			/* TODO: Only save error if cookie is the lobby we're visiting? */
			AppUtils.log("Removing cookie", data.code);
			AppUtils.removeCookie(data.code);
			return Object.assign({}, state, {
				lobby: {
					...state.lobby,
					error: action.error
				}
			});

		case AppConstants.COOKIE_FAILURE_FORGET:
			AppUtils.log("Removing cookie", data.code);
			AppUtils.removeCookie(data.code);
			return Object.assign({}, state, {
				lobby: {
					...state.lobby,
					error: action.error
				}
			});
		case AppConstants.LOBBY_COOKIE:
			/* We got cookie data.cookie for lobby data.code */
			AppUtils.saveCookie(data.lobby, data.cookie);
			AppUtils.log("Cookie should be saved as "+data.lobby+" -> "+data.cookie);
			AppUtils.log(AppUtils.getCookie(data.lobby));
			return state;
		default:
			return state;
	}
}
function ui_dispatcher(state = initialUi, action) {
	let data = action.payload;

	switch(action.type) {
		/* Websocket stuff */
		case AppConstants.WEBSOCKET_STATE:
			AppUtils.log("[Websocket State] Changed from", state.websocketState, "to", data.state);
			return Object.assign({}, state, {
				websocketState: data.state,
				websocketLastError: data.lastError
			});

		/* Language */
		case AppConstants.LANGUAGE_SET:
			for(var x in state.languageAvailable) {
				let v = state.languageAvailable[x];
				if(data.language.toLowerCase() === v.short.toLowerCase()) {
					return Object.assign({}, state, {
						language: v
					});
				}
			}
			/* No hit? */
			return state;

		/* Announcements */
		case AppConstants.ANNOUNCEMENT_ADD:
			/* Don't re-add announcements with the same ID */
			if(data.id && state.announcements.length > 0) {
				for(let i = 0; i < state.announcements.length; i++) {
					if(state.announcements[i].id === data.id) {
						return state;
					}
				}
			}

			return Object.assign({}, state, {
				announcements: [
					...state.announcements,
					{ id: (typeof data.id === 'undefined' || data.id === 0 ? 'C'+CustomAnnouncement++ : data.id), message: data.message, url: data.url }
				]
			});
		case AppConstants.ANNOUNCEMENT_CLOSE:
			return Object.assign({}, state, {
				announcements:
					state.announcements.filter((a) => {
						return (a.id !== data.id);
					})
			});
		case AppConstants.COOKIE_VALIDATE_CLEAR:
			return Object.assign({}, state, {
				ongoingGames: []
			});
		case AppConstants.COOKIE_VALIDATE_SUCCESS:
			return Object.assign({}, state, {
				ongoingGames: [
					...state.ongoingGames,
					{ lobby: data.lobby, question: data.question, players: data.players, state: data.state }
				],
			});
		case AppConstants.COOKIE_VALIDATE_FAILURE:
		case AppConstants.LEAVE_LOBBY_SILENT_SUCCESS:
		case AppConstants.LEAVE_LOBBY_SILENT_FAILURE:
			AppUtils.log("Removing cookie", data.code, "due to", action.type);
			AppUtils.removeCookie(data.code);
			return Object.assign({}, state, {
				ongoingGames:
					state.ongoingGames.filter((a) => {
						return (a.lobby !== data.code);
					})
			});
		default:
			return state;
	}
}

function settings_dispatcher(state = initialUi, action) {
	let data = action.payload;

	AppUtils.log("[settings_dispatcher]", action, state);
	switch(action.type) {
		case AppConstants.SETTINGS_LOAD_SUCCESS:
			return Object.assign({}, state, {
				...data
			});
		default:
			return state;
	}
}
const AppStore = createStore(
	combineReducers(
		{ auth: auth_dispatcher, ui: ui_dispatcher, page: page_dispatcher, settings: settings_dispatcher }
	), initialState, applyMiddleware(WebsocketService)
);
export default AppStore;
