module.exports = {

	/* Login */
	LOGIN: 'LOGIN',
	LOGIN_TICKET: 'LOGIN_TICKET',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	LOGIN_FAILURE: 'LOGIN_FAILURE',
	LOGOUT: 'LOGOUT',
	LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',

	/* Reset password */
	RESET: 'RESET',
	RESET_SUCCESS: 'RESET_SUCCESS',
	RESET_FAILURE: 'RESET_FAILURE',
	RESET_RESET: 'RESET_RESET',

	/* States for Websocket */
	WEBSOCKET_ONLINE: 'WEBSOCKET_ONLINE',
	WEBSOCKET_CLOSED: 'WEBSOCKET_CLOSED',
	WEBSOCKET_ERROR: 'WEBSOCKET_ERROR',
	WEBSOCKET_STATE: 'WEBSOCKET_STATE',
	SUBSCRIBE: 'SUBSCRIBE',
	UNSUBSCRIBE: 'UNSUBSCRIBE',
	DISCONNECT: 'DISCONNECT',

	/* Speaker stuff */
	SPEAKER_NEXT: 'SPEAKER_NEXT',

	/* Lobby stuff */
	CREATE_LOBBY: 'CREATE_LOBBY',
	CREATE_LOBBY_SUCCESS: 'CREATE_LOBBY_SUCCESS',
	CREATE_LOBBY_FAILURE: 'CREATE_LOBBY_FAILURE',
	CREATE_LOBBY_RESET: 'CREATE_LOBBY_RESET',

	JOIN_LOBBY: 'JOIN_LOBBY',
	JOIN_LOBBY_SUCCESS: 'JOIN_LOBBY_SUCCESS',
	JOIN_LOBBY_FAILURE: 'JOIN_LOBBY_FAILURE',
	JOIN_LOBBY_RESET: 'JOIN_LOBBY_RESET',
	LEAVE_LOBBY: 'LEAVE_LOBBY',
	LEAVE_LOBBY_SUCCESS: 'LEAVE_LOBBY_SUCCESS',
	LEAVE_LOBBY_FAILURE: 'LEAVE_LOBBY_FAILURE',
	LEAVE_LOBBY_SILENT: 'LEAVE_LOBBY_SILENT',
	LEAVE_LOBBY_SILENT_SUCCESS: 'LEAVE_LOBBY_SILENT_SUCCESS',
	LEAVE_LOBBY_SILENT_FAILURE: 'LEAVE_LOBBY_SILENT_FAILURE',
	LOBBY_KICKED: 'LOBBY_KICKED',
	KICK: 'KICK',
	LOBBY_TIMELEFT: 'LOBBY_TIMELEFT',

	REMATCH_LOBBY: 'REMATCH_LOBBY',
	REMATCH_LOBBY_SUCCESS: 'REMATCH_LOBBY_SUCCESS',
	REMATCH_LOBBY_FAILURE: 'REMATCH_LOBBY_FAILURE',

	/* These are from the server */
	LOBBY_STATE: 'LOBBY_STATE',
	LOBBY_UPDATE: 'LOBBY_UPDATE',
	LOBBY_PLAYER: 'LOBBY_PLAYER',
	LOBBY_PLAYER_STATUS: 'LOBBY_PLAYER_STATUS',
	LOBBY_ERROR: 'LOBBY_ERROR',
	LOBBY_COOKIE: 'LOBBY_COOKIE',
	LOBBY_ADMIN: 'LOBBY_ADMIN',

	/* Game stuff */
	GAME_START: 'GAME_START',
	GAME_START_FAILURE: 'GAME_START_FAILURE',
	GAME_ANSWER: 'GAME_ANSWER',
	GAME_ANSWER_SUCCESS: 'GAME_ANSWER_SUCCESS',
	GAME_ANSWER_FAILURE: 'GAME_ANSWER_FAILURE',
	GAME_RESET_ANSWER: 'GAME_RESET_ANSWER',
	SHOW_QUESTION: 'SHOW_QUESTION',
	READY: 'READY',
	READY_SUCCESS: 'READY_SUCCESS',
	READY_FAILURE: 'READY_FAILURE',

	/* Settings */
	SETTINGS_LOAD: 'SETTINGS_LOAD',
	SETTINGS_LOAD_SUCCESS: 'SETTINGS_LOAD_SUCCESS',
	SETTINGS_LOAD_FAILURE: 'SETTINGS_LOAD_FAILURE',

	/* UI */
	LANGUAGE_SET: 'LANGUAGE_SET',
	ANNOUNCEMENT: 'ANNOUNCEMENT',
	ANNOUNCEMENT_ADD: 'ANNOUNCEMENT_ADD',
	ANNOUNCEMENT_CLOSE: 'ANNOUNCEMENT_CLOSE',

	/* States */
	STATE_DESYNC: 0,
	STATE_LOBBY: 1,
	STATE_STARTING: 5,
	STATE_QUESTION: 10,
	STATE_DRINK: 15,
	STATE_STREAK: 17,
	STATE_ADVERTISE: 18,
	STATE_WHEEL: 19,
	STATE_DONE: 20,

	STATE_WEBSOCKET_IDLE: 0,
	STATE_WEBSOCKET_OFFLINE: 1,
	STATE_WEBSOCKET_CONNECTING: 2,
	STATE_WEBSOCKET_ONLINE: 3,
	STATE_WEBSOCKET_DISCONNECTED: 4,

	/* Game modes */
	GAME_MODE_NORMAL: 1,
	GAME_MODE_SPEAKER: 2,

	/* Other stuff */
	PING: 'PING',
	PONG: 'PONG',
	COOKIE: 'COOKIE',
	COOKIE_SUCCESS: 'COOKIE_SUCCESS',
	COOKIE_FAILURE: 'COOKIE_FAILURE',
	COOKIE_FAILURE_FORGET: 'COOKIE_FAILURE_FORGET',
	COOKIE_VALIDATE: 'COOKIE_VALIDATE',
	COOKIE_VALIDATE_SUCCESS: 'COOKIE_VALIDATE_SUCCESS',
	COOKIE_VALIDATE_FAILURE: 'COOKIE_VALIDATE_FAILURE',
	COOKIE_VALIDATE_CLEAR: 'COOKIE_VALIDATE_CLEAR',

	CATEGORY_QUESTIONS_PLAYERS: 1,
	CATEGORY_RANDOM_QUESTIONS: 2,
	CATEGORY_PICK_SIDE: 4,
	CATEGORY_MOST_LIKELY: 5,

	/* ERRORS! */
	ERR_NONE: 0,
	ERR_PARAMETER_INVALID: 10,

	/* React only errors */
	ERR_BACKEND_OFFLINE: 50,

	/* Lobby errors */
	ERR_LOBBY_INVALID: 100,
	ERR_LOBBY_NOT_ADMIN: 101,
	ERR_LOBBY_ALREADY_PLAYING: 102,
	ERR_LOBBY_MORE_PLAYERS: 103,
	ERR_LOBBY_STATE: 104,
	ERR_LOBBY_KICKED: 105,

	/* Create game */
	ERR_CREATE_DEVICE: 300,
	ERR_CREATE_MODE: 301,
	ERR_CREATE_FAILED: 302,

	/*  Nickname */
	ERR_NICKNAME_LENGTH: 400,
	ERR_NICKNAME_TAKEN: 401,
	ERR_NICKNAME_INVALID: 402,

	/* Player */
	ERR_PLAYER_NULL: 500,
	ERR_PLAYER_LOBBY: 501,
	ERR_PLAYER_READY_ALREADY: 502,
	ERR_PLAYER_HOST: 503,

	/* Cookie stuff */
	ERR_COOKIE_INVALID: 600,

	/* Answer errors */
	ERR_ANSWER_INVALID: 700,
	ERR_ANSWER_ALREADY: 701,
	ERR_ANSWER_STATE: 702

}
