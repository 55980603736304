import React from 'react'
import { connect } from 'react-redux';

import logoIndexImage from '../images/logo.svg';
import AppUtils from '../utils/app-utils';
import UITimer from './app-ui-timer';

import '../css/beer.css';

class LobbyStarting extends React.Component {

	componentDidMount() {
		AppUtils.setBackgroundBlack();
		AppUtils.vibrate(150);
	}
	componentWillUnmount() {
		AppUtils.setBackgroundLinear();
	}

	render() {
		let tips = [];

		if(this.props.language.tips && this.props.language.tips.length > 0) {
			this.props.language.tips.forEach( (v, i) => {
				tips.push(<div key={i}><i style={{width: "100%"}}>{v}</i></div>);
			});
		}

		return(<div className="container-fluid justify-content-center h-index">
			<div className="logo-container"><img src={logoIndexImage} alt="Logo" className="img-fluid logo mb-2" /></div>
			<div className="row h-100">
				<div className="col-xl-4 col-lg-5 col-md-7 col-sm-9 col-11 box-padding text-center my-auto mx-auto">
					<div className="row">
						<div className="col-12 p-r" style={{height: "90px"}}>
							<div id="glass">
								<div id="beer"></div>
							</div>
							<div id="poignet"></div>
							<div id="mousse_1"></div>
							<div id="mousse_2"></div>
							<div id="mousse_3"></div>
							<div id="mousse_4"></div>
							<div id="mousse_5"></div>
							<div id="mousse_volante"></div>
							<div id="mousse_interieur"></div>
							<div id="mousse_interieur_2"></div>
							<div id="mousse_interieur_3"></div>
							<div id="mousse_interieur_4"></div>
						</div>
					</div>

					<div className="row">
						<div className="col-12">
							<h2 className="lh-11 mt-4 mb-4">{this.props.language.gameStarting.toUpperCase()}</h2>
						</div>
					</div>
					<UITimer seconds={this.props.lobby.timeleft} reverse={true} />
					<div className="row">&nbsp;</div>
					<div className="row justify-content-center"><div className="col-10">{tips}</div></div>
				</div>
			</div>
		</div>);
	}
}

function mapStateToProps(state) {
	return {
		lobby: state.page.lobby,
		language: state.ui.language.lobby.starting
	}
}

export default connect(mapStateToProps)(LobbyStarting);
