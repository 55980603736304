import React from 'react'
import AppActions from '../actions/app-actions.js';
import AppConstants from '../constants/app-constants';
import AppStore from '../stores/app-store.js';
import AppUtils from '../utils/app-utils';
import QRCode from 'qrcode.react';

import UIError from './app-ui-error';

import LobbyStarting from './app-lobby-starting';
import LobbyQuestion from './app-lobby-question';
import LobbyDrink from './app-lobby-drink';
import LobbyStreak from './app-lobby-streak';
import LobbyAdvertise from './app-lobby-advertise';
import LobbyDone from './app-lobby-done';

import logoIndexImage from '../images/logo.svg';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Fullscreen from './app-fullscreen';

class Lobby extends React.Component {
	constructor(props) {
		super(props);

		this.state = { sentCookie: false };
		this.onStart = this.onStart.bind(this);
		this.onLeave = this.onLeave.bind(this);
		this.checkCookie = this.checkCookie.bind(this);
		
	}

	componentDidMount() {
		if(this.props.match.params.code) {
			if(this.props.match.params.code !== this.props.lobby.code) {
				this.checkCookie();
			}
		}

	}
	
	componentDidUpdate(prevProps, prevState, ss) {
		if(prevProps.lobby.state !== AppConstants.STATE_QUESTION && this.props.lobby.state === AppConstants.STATE_QUESTION) {
			AppStore.dispatch(AppActions.GameResetAnswer({}));
		}

		if(prevProps.match.params.code !== this.props.match.params.code) {
			AppUtils.log("Code changed from", prevProps.match.params.code, this.props.match.params.code);
			/* Do we do anything here? Do we check cookie? */
		}
		
		if(prevProps.ui.websocketState !== AppConstants.STATE_WEBSOCKET_ONLINE && this.props.ui.websocketState === AppConstants.STATE_WEBSOCKET_ONLINE) {
			this.checkCookie();
		}

		if(prevProps.lobby.code.length > 0 && this.props.lobby.code.length === 0) {
			/* We probably left the lobby */
			this.props.history.push("/play");
		}
	}

	checkCookie() {
		//AppStore.dispatch(AppActions.Cookie({cookie: "RGykQsDcyAGXmZmyz8bi", code: "1088" }));
		//return;
		let cookie = AppUtils.getCookie(this.props.match.params.code);
		if(cookie !== undefined) {
			this.setState({ sentCookie: true });
			AppStore.dispatch(AppActions.Cookie({cookie: cookie, code: this.props.match.params.code}));
		} else {
			if(this.props.lobby.code === '')
				AppStore.dispatch(AppActions.LobbyError(AppConstants.ERR_COOKIE_INVALID));
		}
	}
	
	onStart() {
		AppStore.dispatch(AppActions.GameStart({code: this.props.match.params.code}));
	}
	onLeave() {
		AppStore.dispatch(AppActions.LeaveLobby());
	}
	onKick(nickname) {
		if(this.props.lobby.admin) AppStore.dispatch(AppActions.Kick({nickname: nickname}));
	}


	render() {
		AppUtils.log("[Lobby]", this.props.lobby);

		/* If we're not online, just show an error. */
		//if(this.props.ui.websocketState !== AppConstants.STATE_WEBSOCKET_ONLINE) {
		if(1===2) {
			return(<div className="container-fluid justify-content-center h-index">
				<div className="logo-container"><img src={logoIndexImage} alt="Logo" className="img-fluid logo mb-2" /></div>
				<div className="row h-100">
					<div className="col-xl-4 col-lg-5 col-md-7 col-sm-9 col-11 box box-shadow box-padding text-center my-auto mx-auto">
						{this.props.language.waitingOnConnection}
						<br /><br />
						<Link to="/play" className="btn btn-custom btn-xl">{this.props.language.back}</Link>
					</div>
				</div>
			</div>);
		}

		/* Check if we're kicked */
		if(this.props.lobby.error === AppConstants.ERR_LOBBY_KICKED) {
			return(<div className="container-fluid justify-content-center h-index">
				<div className="logo-container"><img src={logoIndexImage} alt="Logo" className="img-fluid logo mb-2" /></div>
				<div className="row h-100">
					<div className="col-xl-4 col-lg-5 col-md-7 col-sm-9 col-11 box box-shadow box-padding text-center my-auto mx-auto">
						<UIError message={this.props.lobby.error} />
						<br />
						<Link to="/play" className="btn btn-custom btn-xl">{this.props.language.back}</Link>
					</div>
				</div>
			</div>);
		}

		if(this.props.lobby.error && this.props.lobby.state !== AppConstants.STATE_LOBBY && this.props.lobby.state !== AppConstants.STATE_DONE) {
			return(<div className="container-fluid justify-content-center h-index">
				<div className="logo-container"><img src={logoIndexImage} alt="Logo" className="img-fluid logo mb-2" /></div>
				<div className="row h-100">
					<div className="col-xl-4 col-lg-5 col-md-7 col-sm-9 col-11 box box-shadow box-padding text-center my-auto mx-auto">
						<UIError message={this.props.lobby.error} />
						<br />
						<Link to="/play" className="btn btn-custom btn-xl">{this.props.language.back}</Link>
					</div>
				</div>
			</div>);
		}

		/* If we don't have the lobby code, wait for server to respond to our cookie request (if any) */
		if(this.props.lobby.code === '' || this.props.lobby.state === AppConstants.STATE_DESYNC) {
			let msg = this.props.language.waitingOnServer;
			if(!this.state.sentCookie) msg = this.props.language.noCookie;

			return(<div className="container-fluid justify-content-center h-index">
				<div className="logo-container"><img src={logoIndexImage} alt="Logo" className="img-fluid logo mb-2" /></div>
				<div className="row h-100">
					<div className="col-xl-4 col-lg-5 col-md-7 col-sm-9 col-11 box box-shadow box-padding text-center my-auto mx-auto">
						{msg}
						<br /><br />
						<Link to="/play" className="btn btn-custom btn-xl">{this.props.language.back}</Link>
					</div>
				</div>
			</div>);
		}

		switch(this.props.lobby.state) {
			case AppConstants.STATE_LOBBY:

				let players = [];
				let count = 0;
				this.props.lobby.players.forEach( (v, i) => {
					if(v.nickname.toLowerCase() === "host") {
						count = 1;
						return;
					}
					//let cls = "online";
					//if(!v.online) cls = "offline";
					//let cls = "";
					//players.push(<li key={i} className={"list-group-item "+cls}>
					//	{v.nickname}
					//</li>);
					let kick = "";
					if(this.props.lobby.admin && v.nickname.toLowerCase() !== this.props.lobby.nickname.toLowerCase()) kick = (<div className="float-right"><i className="fas fa-ban c-p" onClick={() => this.onKick(v.nickname)}></i></div>);
					players.push(<tr key={i}><td className="p-3" style={{borderBottom: "1px solid #FFF"}}>{v.nickname.toUpperCase()} {kick}</td></tr>);
				});
				let button = "";
				if(this.props.lobby.admin)
					button = (<div className="pt-5">
						<button className="btn btn-custom btn-xl" type="button" onClick={this.onStart}>
							{this.props.language.start}
						</button>
					</div>);

				let joinText = "";
				if(!this.props.lobby.playing) {
					joinText = (<h2 className="responsive-text-big pb-4">{this.props.language.joinAt}</h2>)
				}

				return(
					<div className="container-fluid text-center h-index">
						<Fullscreen usePopup={true} />
						<div className="row h-100">
							<div className="col-lg-4 my-auto pb-3 pt-1">
								<h2 className="lh-08 mt-4 mb-4 big-text underline">{this.props.language.lobbyCode.toUpperCase()}</h2>
								<h3 className="lh-08 highlight big-text game-code pb-4">{this.props.lobby.code}</h3>
								<h2 className="lh-08 mt-4 mb-4 big-text underline">{this.props.language.qrCode.toUpperCase()}</h2>
								<QRCode value={process.env.REACT_APP_BASE_URL+"/join/"+this.props.lobby.code} />
							</div>
							<div className="col-lg-5 col-md-8 my-auto mx-sm-auto mx-lg-0 pt-5">
								{joinText}
								<div className="box-shadow">
									<div className="responsive-text p-2" style={{backgroundColor: "#363656"}}>
										{AppUtils.replaceLanguage(this.props.language.players.toUpperCase(), { "%p": this.props.lobby.players.length-count })}
									</div>

									<div className="text-center pb-3" style={{backgroundColor: "#28263E", minHeight: "200px"}}>
										<table className="mx-auto" width="80%">
											<tbody>
												{players}
											</tbody>
										</table>
									</div>
									<div className="responsive-text p-2 c-p hover" style={{backgroundColor: "#363656"}} onClick={this.onLeave}>
										{this.props.language.leaveLobby.toUpperCase()}
									</div>

								</div>

								{button}

								<div className="row justify-content-sm-center mt-3">
									<div className="col-sm-6">
										<UIError message={this.props.lobby.error} />
									</div>
								</div>
							</div>
						</div>
					</div>
				);

			case AppConstants.STATE_STARTING:
				return(<LobbyStarting />);
			case AppConstants.STATE_QUESTION:
				return(<React.Fragment><Fullscreen usePopup={false} /><LobbyQuestion /></React.Fragment>);
			case AppConstants.STATE_DRINK:
				return(<React.Fragment><Fullscreen usePopup={false} /><LobbyDrink /></React.Fragment>);
			case AppConstants.STATE_STREAK:
				return(<LobbyStreak />);
			case AppConstants.STATE_ADVERTISE:
				return(<LobbyAdvertise />);
			case AppConstants.STATE_DONE:
				return(<React.Fragment><Fullscreen usePopup={false} /><LobbyDone /></React.Fragment>);

			default:
				return(<div className="container text-center my-auto">{this.props.language.invalidGameState}</div>);
		}
	}
}

function mapStateToProps(state) {
	return {
		lobby: state.page.lobby,
		ui: state.ui,
		settings: state.settings,
		language: state.ui.language.lobby,
	}
}

export default connect(mapStateToProps)(Lobby);
