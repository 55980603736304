import React from 'react'
import { connect } from 'react-redux';

import AppConstants from '../constants/app-constants';
import AppUtils from '../utils/app-utils';

class ServerStatus extends React.Component {

	render() {
		let status = "";
		if(this.props.ui.websocketState === AppConstants.STATE_WEBSOCKET_ONLINE) return (<React.Fragment></React.Fragment>);

		switch(this.props.ui.websocketState) {
			case AppConstants.STATE_WEBSOCKET_IDLE:
				status = this.props.language.idle;
				break;
			case AppConstants.STATE_WEBSOCKET_CONNECTING:
				status = this.props.language.connecting;
				break;
			case AppConstants.STATE_WEBSOCKET_OFFLINE:
				status = this.props.language.offline;
				break;
			case AppConstants.STATE_WEBSOCKET_DISCONNECTED:
				let e = this.props.ui.websocketLastError;
				if(!isNaN(this.props.ui.websocketLastError) && this.props.ui.websocketLastError > 0) e = this.props.errors[this.props.ui.websocketLastError];
				status = AppUtils.replaceLanguage(this.props.language.disconnected, { "%e": e });
				break;
			default:
				status = this.props.language.unknown+" (E"+this.props.ui.websocketState+")";
				break;
		}
		return(<div className="server-status text-center pl-2 pr-2">
			{status}
		</div>);
	}
}

function mapStateToProps(state) {
	return {
		ui: state.ui,
		errors: state.ui.language.errors,
		language: state.ui.language.serverStatus
	}
}

export default connect(mapStateToProps)(ServerStatus);
