import React from 'react'
import { Link } from 'react-router-dom';

import logoIndexImage from '../images/logo.svg';

import { connect } from 'react-redux';

class Help extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
	}

	render() {
		let helpContent = [];
		this.props.language.helpContent.forEach( (v, i) => {
			helpContent.push(<div key={i}>{v}</div>);
		});
		let aboutUsContent = [];
		this.props.language.aboutUsContent.forEach( (v, i) => {
			aboutUsContent.push(<div key={i}>{v}</div>);
		});
		let categoriesContent = [];
		for(let x in this.props.categories) {
			categoriesContent.push(<div key={x}><b>{this.props.categories[x]}</b></div>);
			this.props.language.categoriesContent[x].forEach( (v, i) => {
				categoriesContent.push(<div key={"sub-"+x+"-"+i}>{v}</div>);
			});
			categoriesContent.push(<div key={"end-"+x}>&nbsp;</div>);
		};
		let otherInfoContent = [];
		this.props.language.otherInfoContent.forEach( (v, i) => {
			otherInfoContent.push(<div key={i}>{v}</div>);
		});
		return(
			<div className="container-fluid justify-content-center h-index">
				<div className="logo-container"><img src={logoIndexImage} alt="Logo" className="img-fluid logo mb-2" /></div>
				<div className="row h-100 pt-4">
					<div className="col-xl-6 col-lg-7 col-md-9 col-sm-10 col-11 my-auto box box-shadow box-padding text-center container-padding mx-auto">
						<div className="t-l">
							<Link to="/play"><i className="fas fa-arrow-left"></i> {this.props.language.back.toUpperCase()}</Link>
						</div>

						<div className="row justify-content-center">
							<div className="col-lg-12 col-sm-12">
								<div className="new-settings mx-auto justify-content-center">
								{/*<div className="row justify-content-center responsive-text pb-2">{this.props.language.header.toUpperCase()}</div>
									<div className="row justify-content-center">
										<div className="col-10">
											{helpContent}
										</div>
									</div>
									<div className="row">&nbsp;</div>
									<div className="row justify-content-center responsive-text pb-2">{this.props.language.howItLooks.toUpperCase()}</div>
									<div className="row justify-content-center">
										<div className="col-md-4 col-lg-4 mb-3 pl-1 pr-1">
											<img src="https://cdn.moes.beer/images/help/help1.png?v=5" height="300" width="165" alt="Question 1" />
										</div>
										<div className="col-md-4 col-lg-4 mb-3 pl-1 pr-1">
											<img src="https://cdn.moes.beer/images/help/help2.png?v=5" height="300" width="165" alt="Question 2" />
										</div>
										<div className="col-md-4 col-lg-4 mb-3 pl-1 pr-1">
											<img src="https://cdn.moes.beer/images/help/help3.png?v=5" height="300" width="165" alt="Drink page" />
										</div>
									</div>
									<div className="row">&nbsp;</div>
								*/}
									<div className="row justify-content-center responsive-text pb-2">{this.props.language.categories.toUpperCase()}</div>
									<div className="row justify-content-center">
										<div className="col-10">
											{categoriesContent}
										</div>
									</div>
									<div className="row">&nbsp;</div>
									<div className="row justify-content-center responsive-text pb-2">{this.props.language.aboutUs.toUpperCase()}</div>
									<div className="row justify-content-center">
										<div className="col-10">
											{aboutUsContent}
										</div>
									</div>
									<div className="row">&nbsp;</div>
									<div className="row justify-content-center responsive-text pb-2">{this.props.language.otherInfo.toUpperCase()}</div>
									<div className="row justify-content-center">
										<div className="col-10">
											{otherInfoContent}
										</div>
									</div>
									<div className="row">&nbsp;</div>
									<div className="row justify-content-center">
										<div className="col-10">
											<Link to={{ pathname: "https://facebook.com/moesbeergame"}} target="_blank" rel="noopener noreferrer" style={{color: '#734e9a'}}><i className="fab fa-facebook fa-2x"></i></Link>
											&nbsp;&nbsp;
											<Link to={{ pathname: "https://instagram.com/moesbeergame"}} target="_blank" rel="noopener noreferrer"  style={{color: '#734e9a'}}><i className="fab fa-instagram fa-2x"></i></Link>
										</div>
										<div className="col-10"><small>moes.Beer Version {process.env.REACT_APP_VERSION}</small></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		help: state.page.help,
		categories: state.ui.language.categories,
		language: state.ui.language.help,
	}
}

export default connect(mapStateToProps)(Help);
