import React from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import AppUtils from '../utils/app-utils';
import parse from 'html-react-parser';

import logoIndexImage from '../images/logo.svg';

import Ongoing from './app-ongoing';

class Play extends React.Component {
	render() {
		return(
			<div className="container-fluid justify-content-center h-index">
				<Ongoing />
				<div className="logo-container"><img src={logoIndexImage} alt="Logo" className="img-fluid logo mb-2" /></div>
				<div className="row h-100">
					<div className="col-xl-4 col-lg-5 col-md-7 col-sm-9 col-11 box box-shadow box-padding text-center my-auto mx-auto">
						<Link to="/join" className="btn btn-custom btn-custom-height mb-5">
							{this.props.language.join}
						</Link>
						<br />
						<Link to="/create" className="btn btn-custom btn-custom-height mb-5">
							{this.props.language.create}
						</Link>
						<br />
						<Link to="/help" className="btn btn-custom btn-custom-height">
							{this.props.language.help}
						</Link>
						<div className="col-10 mt-5 pt-4 pb-2 text-center mx-auto" style={{boxShadow: '#000 0px 0px 4px 0px'}}>
							<p className="text-center">{this.props.language.thanksForVisiting}</p>
							<p className="text-center">{parse(AppUtils.replaceLanguage(this.props.language.followSocialMedias, { "%i": '<a href="https://instagram.com/moesbeergame" target="_blank" rel="noreferrer">Instagram</a>'}))}</p>
							<p className="text-right"><i>From Sweden <img src="https://cdn.moes.beer/images/bjorkloven.ico" alt="Björklöven-logo" title="Björklöven" />, with love</i></p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
function mapStateToProps(state) {
	return {
		language: state.ui.language.play,
	}
}
export default connect(mapStateToProps)(Play);