import React from 'react'
import { connect } from 'react-redux';
//import { Link } from 'react-router-dom';


//import AppActions from '../actions/app-actions.js';
//import AppStore from '../stores/app-store.js';
import AppUtils from '../utils/app-utils';
//import AppConstants from '../constants/app-constants';

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import fscreen from 'fscreen';

class Fullscreen extends React.Component {
	constructor(props) {
		super(props);

		this.state = { haveAsked: false, isFullscreen: false };
		this.onFullscreen = this.onFullscreen.bind(this);
		this.onFullscreenChange = this.onFullscreenChange.bind(this);
	}
	componentDidMount() {
		if(fscreen.fullscreenElement && !this.state.isFullscreen) {
			AppUtils.log("[Fullscreen] We are fullscreen but state is not fullscreen.");
			this.setState({ isFullscreen: true });
		}
		fscreen.addEventListener('fullscreenchange', this.onFullscreenChange, false);
	}
	componentWillUnmount() {
		fscreen.removeEventListener('fullscreenchange', this.onFullscreenChange, false);
	}

	onFullscreen(x) {
		this.setState({haveAsked: true});
		if(!x) {
			return;
		}
		
		if (fscreen.fullscreenEnabled) {
			fscreen.requestFullscreen(document.documentElement);
		}
	}
	onFullscreenChange() {
		if (fscreen.fullscreenElement !== null) {
			this.setState({ isFullscreen: true });
			AppUtils.log('Entered fullscreen mode');
		} else {
			this.setState({ isFullscreen: false });
			AppUtils.log('Exited fullscreen mode');
		}
	}
	

	render() {
		AppUtils.log("[Fullscreen] State is", this.state.isFullscreen, "and element is", fscreen.fullscreenElement);

		/* If we're already in fullscreen, don't do anything */
		if(this.state.isFullscreen) return(<React.Fragment></React.Fragment>);
		
		/* Don't show us if they've clicked something. */
		if(this.state.haveAsked || !this.props.usePopup) {
			if(!this.state.isFullscreen) {
				return(<div className="fullscreen-container">
					<button className="fullscreen-toggle" onClick={() => this.onFullscreen(true)}>
						<i className="fas fa-expand"></i>
					</button>
				</div>);
			}
			/* We are in fullscreen, don't show expand thingie */
			return(<React.Fragment></React.Fragment>);
		}

		if(this.props.usePopup) {
			return(
				<Popup open={true} closeOnDocumentClick={false} modal contentStyle={{backgroundColor: "#2A2A55", width: "unset", border: "0px", borderRadius: "10px", color: "#FFF"}}>
					<div className="container-lg">
						<div className="header text-center mt-4">
							<h3>{this.props.language.question}</h3>
						</div>
						<div className="row">&nbsp;</div>
						<div className="row justify-content-sm-center">
							<div className="col-sm-12 col-lg-6 col-md-12 mb-3">
								<button className="btn btn-block btn-xl btn-custom btn-popup b-u" onClick={() => this.onFullscreen(false)}>{this.props.language.no}</button>
							</div>
							<div className="col-sm-12 col-lg-6 col-md-12 mb-3">
								<button className="btn btn-block btn-xl btn-custom btn-popup btn-popup-focus" onClick={() => this.onFullscreen(true)}>{this.props.language.yes}</button>
							</div>
						</div>
					</div>
				</Popup>
			);
		}
		
		return(
			<React.Fragment></React.Fragment>
		);
	}
}
function mapStateToProps(state) {
	return {
		language: state.ui.language.fullscreen,
	}
}
export default connect(mapStateToProps)(Fullscreen);
