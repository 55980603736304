import React from 'react'
import AppActions from '../actions/app-actions.js';
import AppStore from '../stores/app-store.js';
import AppUtils from '../utils/app-utils';

import logoIndexImage from '../images/logo.svg';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class Speaker extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			current: 0,
			showAnswer: false,
		};

		this.onShowAnswer = this.onShowAnswer.bind(this);
		this.onNext = this.onNext.bind(this);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
	}

	onShowAnswer(e) {
		this.setState({showAnswer: true });
	}
	onNext(e) {
		if(this.props.speaker.questions && this.props.speaker.questions.length > this.state.current) {
			AppStore.dispatch(AppActions.SpeakerNext({ lobby: this.props.speaker.code, question: (this.state.current+1)}));
			this.setState({current: (this.state.current+1), showAnswer: false });
		}
	}

	render() {
		AppUtils.log("[Speaker]", this.props.speaker);

		if(this.props.speaker.questions.length === 0) {
			return(<div className="container-fluid justify-content-center h-index">
				<div className="logo-container"><img src={logoIndexImage} alt="Logo" className="img-fluid logo mb-2" /></div>
				<div className="row h-100">
					<div className="col-xl-4 col-lg-5 col-md-7 col-sm-9 col-11 box box-shadow box-padding text-center my-auto mx-auto">
						<h2 className="lh-11 mt-4 mb-4">{this.props.language.noQuestionsFound}</h2>

						<div className="row">&nbsp;</div>
						<div className="row justify-content-sm-center">
							<div className="col-lg-8 col-sm-8">
								<Link to="/create" className="btn btn-custom btn-xl">{this.props.language.create}</Link>
							</div>
						</div>
						<div className="row">&nbsp;</div>
						<div className="row justify-content-sm-center">
							<div className="col-lg-8 col-sm-8">
								<Link to="/" className="btn btn-custom btn-xl">{this.props.language.back}</Link>
							</div>
						</div>
						
					</div>
				</div>
			</div>);
		}

		/* Last question has been answered. We be done now! */
		if(this.state.current === this.props.speaker.questions.length) {
			return(<div className="container-fluid justify-content-center h-index">
				<div className="logo-container"><img src={logoIndexImage} alt="Logo" className="img-fluid logo mb-2" /></div>
				<div className="row h-100">
					<div className="col-xl-4 col-lg-5 col-md-7 col-sm-9 col-11 box box-shadow box-padding text-center my-auto mx-auto">
						<h2 className="lh-11 m-4">{this.props.language.roundFinished}</h2>
				
						<div className="row">&nbsp;</div>
						<div className="row justify-content-sm-center">
							<div className="col-lg-8 col-sm-8">
								<Link to="/create" className="btn btn-custom btn-xl">{this.props.language.create}</Link>
							</div>
						</div>
						<div className="row">&nbsp;</div>
						<div className="row justify-content-sm-center">
							<div className="col-lg-8 col-sm-8">
								<Link to="/play" className="btn btn-custom btn-xl">{this.props.language.back}</Link>
							</div>
						</div>
					</div>
				</div>
			</div>);
		}

		let q = this.props.speaker.questions[this.state.current];
		let options = [];
		let hasAnswer = undefined;
		let extra = "";

		if(this.state.current < this.props.speaker.questions.length && this.props.speaker.questions[this.state.current].correct >= 0) {
			let c = this.props.speaker.questions[this.state.current].correct
			hasAnswer = (<div className="col-lg-12 col-sm-10 mb-2">
				<button className="btn btn-custom btn-xl btn-moes-shadow">{this.props.speaker.questions[this.state.current].options[c].option}</button>
			</div>);
			let e = this.props.speaker.questions[this.state.current].extra;
			if(e && e.length > 0) extra = (<div className="col-sm-12 text-center">{this.props.speaker.questions[(this.state.current-1)].extra}</div>);
		}

		if(q.options.length > 0) {
			//options.push(<div className="row">&nbsp;</div>);
			q.options.forEach( (v, i) => {
				//if(i > 0) options += ", ";
				options.push(<div key={i} className="col-sm-12"><h3>{v.option.replace("%p", this.props.language.personLeftOfYou)}</h3></div>);
				//options += (i+1)+") "+v.option+"";
			});
		}

		let tip = "";
		let tips = [];
		if(this.state.current === 0) {
			/* Show a tip */

			this.props.language.tips.forEach( (v, i) => {
				tips.push(<div className="col-sm-12" key={i}><b><i>{v}</i></b></div>);
			});
			tip = (<div className="row justify-content-center pt-4">{tips}</div>);
		}

		return(
			<div className="container-fluid h-index">
				<div className="row h-100">
					<div className="col-10 my-auto mx-auto text-center">
					{tip}
					<div className="row">&nbsp;</div>
		
					<div className="row justify-content-center"><div className="col-lg-12 col-sm-12"><h2 className="lh-11 question-size mb-2">{q.question}</h2></div></div>

					<div className="row justify-content-center">
						{options}
					</div>
					<div className="row">&nbsp;</div>

					<div className="row justify-content-center">
						{hasAnswer && !this.state.showAnswer && <div className="col-sm-12 col-lg-6 col-md-12 mb-3">
							<button className="btn btn-custom btn-xl" type="button" onClick={this.onShowAnswer}>{this.props.language.showAnswer}</button>
						</div>}
						{(this.state.showAnswer || !hasAnswer) && <div className="col-sm-12 col-lg-6 col-md-12 mb-3">
							{hasAnswer}<br />
							{extra}<br />
							<button className="btn btn-custom btn-xl" type="button" onClick={this.onNext}>{this.props.language.next}</button>
						</div>}
					</div>
				</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		speaker: state.page.speaker,
		settings: state.settings,
		language: state.ui.language.speaker
	}
}

export default connect(mapStateToProps)(Speaker);
