import React from 'react'
import { Link } from 'react-router-dom';

import logoIndexImage from '../images/logo.svg';

class E404 extends React.Component {
	render() {
		return(
			<div className="container-fluid justify-content-center h-index">
				<div className="logo-container"><img src={logoIndexImage} alt="Logo" className="img-fluid logo mb-2" /></div>
				<div className="row h-100">
					<div className="col-xl-4 col-lg-5 col-md-7 col-sm-9 col-11 box box-shadow box-padding text-center my-auto mx-auto">
						<h2 className="lh-11 mb-4">404 - Page not found</h2>
						D'oh! We can't find this page!<br />
						It can have been renamed, removed or never existed.<br />
						<br />
						Please head back to <Link to="/">the start</Link>
					</div>
				</div>
			</div>
		);
	}
}

export default E404;
