const LanguageEnglish = {
	/* ID must match database */
	id: 1,
	name: 'english',
	short: 'EN',
	flag: 'https://moes.beer/images/languages/english.svg',

	categories: {
		1: "Spill your beans",
		2: "Random Questions",
		4: "Pick a Side",
		5: "Most Likely",
	},

	/* Index */
	index: {
		title: "The classic <drinking> <games> gathered online",

		intro: [
			"What movie describes your sexlife?",
			"Who is most likely to answer the phone in the middle of sex?",
			"These are the kind of questions you will have to face when playing moes Beer.",
		],

		outro: [
			"Moes.beer is a free online game where each participant connects with their mobile to answer questions.",
			"For optimal experience the questions should be streamed to a TV,",
			"either through the SmartTV's web browser or a computer connected to it.",
			"Otherwise, there is support to only play through mobiles."
		],

		socialMedia: "Follow us on social media!",

		play: "Play"
	},

	/* Play */
	play: {
		create: "Create Game",
		join: "Join Game",
		help: "Help",
		thanksForVisiting: "Thanks for visiting, we hope you'll enjoy playing Moes.Beer!",
		followSocialMedias: "We'd appreciate it if you would follow us on %i and spread the word! 🍺",
	},

	/* Ongoing games */
	ongoing: {
		ongoing: "Ongoing Game(s)",
		info: "",
	},

	fullscreen: {
		question: "Do you want to play in fullscreen?",
		yes: "Yes",
		no: "No"
	},

	/* Create */
	create: {
		back: "Back",
		next: "Next",
		gameMode: "Pick a Game Mode",
		gameModeStandard: "Standard",
		gameModeSpeaker: "Speaker",
		selectMode: "Please select a mode for more information",
		deviceFor: "This device is for",
		hosting: "Displaying Questions",
		playing: "Answering Questions",
		yourName: "Your name",
		questionsLanguage: "Language on Questions",
		categories: "Categories",
		createLobby: "Create Lobby",

		modesInfo: {
			1: [
				"Everyone needs to join on their own device.",
				"They will use their device to answer the questions.",
				" ",
				"Pick this one if you plan on showing the questions on a TV (Smart-TVs web-browser or computer plugged to it), or play yourself."
			],
			2: [
				"No-one will be able to join this lobby.",
				"You will need to read questions out loud.",
				" ",
				"Pick this one if you wanna play a game of most likely or pick a side.",
			]
		}
	},

	/* Join */
	join: {
		back: "Back",
		yourName: "Name",
		lobbyCode: "Code",
		joinLobby: "Join Lobby",
	},

	/* Help */
	help: {
		back: "Back",
		header: "Help",
		howItLooks: "How It Looks",
		categories: "Categories",
		aboutUs: "About Us",
		otherInfo: "Other Information",

		helpContent: [
			"What movie describes your sexlife? Who is most likely to answer the phone in the middle of sex?",
			"These are the kind of questions you will have to face when playing moes Beer.",
		],
		aboutUsContent: [
			"Many of the questions are community made.",
			"We do not take any responsible for where or from whom our users found the questions.",
			"We do our best to screen all of the questions and reject, or modify, those containing strong language.",
			"We can not be held responsible for the questions added by our community.",
			"Got a question or game question, feedback, an idea or are you just angry? Contact us by sending an email to support@moes.beer."
		],
		categoriesContent: {
			"1": ["In this category a randomly chosen player will get a question.", "What they pick will be the correct answer - how well do you know the other people at the party?"],
			"2": ["Is it illegal to kill Santa Clause in Canada? Is crossword puzzle a younger invention than air planes?", "In this category, random knowledge is the key to winning."],
			"4": ["Be invisible or able to fly? Pineapple on a pizza, yey or nay?", "Here you pick a side, and the side with the most picks wins - the other side drinks!"],
			"5": ["Who's most likely to break up with someone over text message? Who's most likely to puke tonight?", "Up to four randomly chosen as options, time to become f(r)iends."]
		},
		otherInfoContent: [
			"Cookies",
			"We use cookies on our website to improve your experience.",
			"Moes.Beer uses third-party cookies in the form of analytics cookies and advertising cookies. Through this we get to know you a little better by collecting information about how you as a user interact with our website.",
			"If you do not want to accept cookies, you can setup your browser so that the cookie files are not downloaded to your device.",
			" ",
			"Facebook Pixel",
			"We use the Facebook pixel. This is to be able to show ads on Facebook that suit you a little better, provided you are a Facebook user and have given Facebook permission to do so.",
			"If you do not wish to give Facebook permission for this, you can remove your approval via your Facebook account",
		],
	},

	/* Speaker mode */
	speaker: {
		noQuestionsFound: "No questions found. Refreshing the page sadly terminates the lobby.",
		options: "This question has the following options:",
		personLeftOfYou: "person left of you",
		showAnswer: "Show answer",
		back: "Back",
		next: "Next",
		create: "Create a new game",
		roundFinished: "Round finished! If you want to play again, create a new speaker lobby.",
		tips: [
			"Tip: On questions with true/false, it's smart to let people raise their hand if they vote for true.",
			"Also: pressing BACK on your phone/tablet/browser will send you to the first menu and cancel this game. Please use the buttons.",
		],
	},

	/* Lobby */
	lobby: {
		joinAt: "Join @ https://moes.beer",
		waitingOnConnection: "Waiting for connection towards the game server...",
		waitingOnServer: "Waiting on server to verify access to this lobby.",
		noCookie: "You are not in this lobby. Please rejoin it.",
		lobbyCode: "Game Code",
		qrCode: "QR code",
		leaveLobby: "Leave Lobby",
		invalidGameState: "Invalid game state detected.",

		players: "%p Players",
		start: "Start game",
		back: "Back",

		/* Starting */
		starting: {
			gameStarting: "Game is starting!",
			tips: [
				"One correct answer equals one point",
				"Decide on how many chugs each wrong answer is!"
			]
		},

		/* Question page */
		question: {
			pressTimerToHide: "Press here to hide the question",
			pressTimerToShow: "Press here to show the question",
		},

		/* Drink */
		drink: {
			correctAnswer: "Correct Answer",
			draw: "Draw",
			yatzy: "YATZY! Everyone picked the same side - everyone drinks!",
			missingOption: "Missing Option/No Answer",
			afk: "AFK/No answer",
			none: "None",

			showVotes: "Show Votes",
			hideVotes: "Hide Votes",

			answerCorrect: "You were CORRECT!",
			answerIncorrect: "You guessed WRONG!",

			ready: "Ready",
			isReady: "You are ready",
			readyStatus: "%c players ready",
			questionsDone: "Question %q out of %t done"
		},

		advertise: {
			advertisement: "Advertisement",
		},
		
		/* Done */
		done: {
			followUs: "Follow us!",
			theWinnerIs: "The winner is...",
			thanksForPlaying: "Thanks for playing moes.Beer!",
			points: "Points",
			nickname: "Name",
			score: "Score",
			leaveLobby: "Leave",
			playAgain: "Play Again",
			playAgainWaiting: "Waiting on Admin",
		},
	},

	/* Server status */
	serverStatus: {
		idle: "Unable to connect. Please refresh this window to try again.",
		connecting: "Connecting...",
		offline: "Unable to connect. Trying to reconnect.",
		unknown: "Unknown server error. Please refresh this window.",
		disconnected: "You have been disconnected from the server: %e",
	},

	/* Errors */
	errors: {
		0: "",
		10: "Invalid parameters",
		50: "Backend appears to be offline",

		100: "Invalid lobby",
		101: "You're not the host",
		102: "You are already in a lobby",
		103: "Not enough players",
		104: "Lobby is in invalid state",
		105: "You have been kicked",
		106: "Lobby has already started",

		300: "Missing device",
		301: "Invalid game mode",
		302: "Failed to create game. Check your settings.",

		400: "Nickname too long",
		401: "Nickname already taken",
		402: "Nickname invalid (only a-z allowed)",

		500: "You are not authenticated",
		501: "You have not joined a lobby",
		502: "Player already ready",
		503: "You are the host",

		600: "No cookie found for this lobby",

		700: "Invalid answer",
		701: "You can't answer right now",

		801: "Newer connection found, make sure you only have one tab open",
	}
};

export default LanguageEnglish;