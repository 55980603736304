import React from 'react'
import { Link } from 'react-router-dom';
import AppActions from '../actions/app-actions.js';
import AppStore from '../stores/app-store.js';
import AppUtils from '../utils/app-utils';
import AppConstants from '../constants/app-constants';
import UIError from './app-ui-error';

import Ongoing from './app-ongoing';

import logoIndexImage from '../images/logo.svg';

import { connect } from 'react-redux';

class Create extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedMode: 0,
			mode: 0,
			device: "HOSTING",
			nickname: "",
			categories: [1, 4, 5],
			language: this.props.languageid,
			chugs: 2
		};

		this.onSelectMode = this.onSelectMode.bind(this);
		this.onCreate = this.onCreate.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onChangeCategories = this.onChangeCategories.bind(this);
	}

	componentDidMount() {
		if(this.props.match.params.mode && !isNaN(this.props.match.params.mode)) {
			this.setState({ mode: parseInt(this.props.match.params.mode, 10) });
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(this.props.create.success && !prevProps.create.success) {
			if(this.props.create.mode === 1)
				this.props.history.push("/lobby/"+this.props.create.code);
			else if(this.props.create.mode === 2)
				this.props.history.push("/speaker");
		}
		
		if(prevState.mode !== this.state.mode && !isNaN(this.state.mode)) {
			/* Mode has been changed, so reset this.state.categories to the ones we can use */
			this.setState({categories: AppUtils.getCategoriesForMode(this.props.settings.category_modes, this.state.mode)},
			function() {
				if(this.state.mode === 1) {
					var a = [...this.state.categories];
					a.splice(a.indexOf(AppConstants.CATEGORY_RANDOM_QUESTIONS), 1);
					this.setState({ categories: a});
				}
			});
		}
		if(this.props.languageid !== prevProps.languageid) {
			this.setState({ language: parseInt(this.props.languageid, 10) });
		}

		if(this.props.match.params.mode !== prevProps.match.params.mode) {
			let newValue = 0;
			if(this.props.match.params.mode && !isNaN(this.props.match.params.mode)) newValue = parseInt(this.props.match.params.mode, 10);
			this.setState({ mode: newValue });
		}
	}

	onCreate() {
		AppStore.dispatch(AppActions.CreateLobby(this.state));
	}
	onChange(name, e) {
		if(name === "mode") this.setState({ mode: parseInt(e, 10) });
		else if(name === "device") this.setState({ device: e.target.value });
		else if(name === "categories") this.setState({ categories: e.target.value });
		else if(name === "chugs") this.setState({ chugs: e.target.value });
		else if(name === "nickname") this.setState({ nickname: e.target.value.trim() });
		else if(name === "language") this.setState({ language: parseInt(e.target.value, 10) });
	}
	onChangeCategories(e) {
		let iv = parseInt(e.target.value, 10);
		if(this.state.categories.indexOf(iv) !== -1) {
			var a = [...this.state.categories];
			a.splice(a.indexOf(iv), 1);
			this.setState({categories: a});
		}
		else {
			this.setState({categories: [ ...this.state.categories, iv ]});
		}
	}
	onSelectMode(m) {
		this.setState({selectedMode: m});
	}

	render() {
		let out = [];
		
		/* Pick mode screen */
		if(this.state.mode === 0) {
			let gameOne = [];
			let gameTwo = [];
			this.props.language.modesInfo[1].forEach( (v, i) => {
				gameOne.push(<div key={i}>
					{v}
				</div>);
			});
			this.props.language.modesInfo[2].forEach( (v, i) => {
				gameTwo.push(<div key={i}>
					{v}
				</div>);
			});
			return(<div className="container-fluid justify-content-center h-index">
				<Ongoing />
				<div className="logo-container"><img src={logoIndexImage} alt="Logo" className="img-fluid logo mb-2" /></div>
				<div className="row h-100">
					<div className="col-xl-7 col-lg-8 col-md-9 col-sm-10 col-11 my-auto box box-shadow box-padding text-center mx-auto">
						<div className="t-l">
							<Link to="/play"><i className="fas fa-arrow-left"></i> {this.props.language.back.toUpperCase()}</Link>
						</div>
						<div className="row justify-content-center pt-2">
							<div className="col-10">
								<h2 className="responsive-text">{this.props.language.gameMode.toUpperCase()}</h2>
							</div>
						</div>
						<div className="row justify-content-center responsive-text-small-rem"> {/* Not justify-content-around because we have margin */}
							<Link className="col-xl-5 game-mode-box mr-20" to="/create/1">
								<div className="">
									<img className="pb-3" src="https://cdn.moes.beer/images/create/screen.png" style={{height: "75px"}} alt={this.props.language.gameModeStandard} />
									<h2 style={{fontSize: '18pt'}}>{this.props.language.gameModeStandard}</h2>
									{gameOne}
								</div>
							</Link>
							<Link className="col-xl-5 game-mode-box ml-20" to="/create/2">
								<div className="">
									<img className="pb-3" src="https://cdn.moes.beer/images/create/phone.png" style={{height: "75px"}} alt={this.props.language.gameModeSpeaker} />
									<h2 style={{fontSize: '18pt'}}>{this.props.language.gameModeSpeaker}</h2>
									{gameTwo}
								</div>
							</Link>
						</div>
					</div>
				</div>
			</div>);

		}

		/* Mode is picked */
		let categories = [];
		let chugs = [];
		let language = [];
		let mode = AppUtils.getArrayWithId(this.props.settings.modes, this.state.mode);
		
		this.props.settings.categories.forEach( (v, i) => {
			if(mode && AppUtils.categoryHasMode(this.props.settings.category_modes, v.id, mode.id)) {
				categories.push(<div key={i} className="form-check form-check-inline">
					<input className="form-check-input" type="checkbox" id={"category"+v.id} value={v.id} checked={this.state.categories.indexOf(v.id) !== -1} onChange={this.onChangeCategories} />
					<label className="form-check-label" htmlFor={"category"+v.id}>{this.props.categories[v.id]}</label>
				</div>);
			}
		});
		this.props.settings.chugs.forEach( (v, i) => {
			chugs.push(<option key={v.amount} value={v.amount}>{v.name}</option>);
		});
		this.props.settings.languages.forEach( (v, i) => {
			language.push(<div key={v.id} className="form-check form-check-inline">
				<input className="form-check-input radio-hide" type="radio" name="language" id={v.id} value={v.id} checked={this.state.language === v.id} onChange={(e) => this.onChange("language", e)} />
				<label className="form-check-label btn btn-custom btn-radio btn-xl" htmlFor={v.id}>
					{/*<img className="radio-image" src={v.image} alt={v.language} />
					<br />*/}
					{v.language}
				</label>
			</div>);
		});

		/* Show or hide Nickname input */
		let nick_cls = "d-none";
		if(this.state.device === "PLAYING") nick_cls = "";

		let gameModePicked = "";
		/* Should always be true */
		if(mode) {
			//gameModePicked = (<div className="category-image-top-container"><div><img src={mode.image} alt={mode.name} /></div><span className="responsive-text-small">{mode.name.toUpperCase()}</span></div>);

			if(mode.id === 1) {
				out.push(<div key={101} className="row justify-content-center responsive-text pb-2">
					<div className="col-8">
						{this.props.language.deviceFor.toUpperCase()}
					</div>
				</div>);
				let checked = "";
				if(this.state.device === "HOSTING") checked = "btn-marked";
				
				out.push(<div key={105} className="form-check form-check-inline pr-2">
					<input className="form-check-input radio-hide" type="radio" name="hostType" id="HOSTING" value="HOSTING" checked={this.state.device === "HOSTING"} onChange={(e) => this.onChange("device", e)} />
					<label className="form-check-label" htmlFor="HOSTING">
						<div className={"radio-div "+checked}>
							<img className="radio-image" src="https://cdn.moes.beer/images/create/screen-button.png" alt="Hosting"/>
						</div>
						<b>{this.props.language.hosting.toUpperCase()}</b>
					</label>
				</div>);
				if(checked === "") checked = "btn-marked";
				else checked = "";
				
				//btn btn-custom-big-image btn-radio
				out.push(<div key={106} className="form-check form-check-inline">
					<input className="form-check-input radio-hide" type="radio" name="hostType" id="PLAYING" value="PLAYING" checked={this.state.device === "PLAYING"} onChange={(e) => this.onChange("device", e)} />
					<label className="form-check-label" htmlFor="PLAYING">
						<div className={"radio-div "+checked}>
							<img className="radio-image" src="https://cdn.moes.beer/images/create/phone-button.png" alt="Playing" />
						</div>
						<b>{this.props.language.playing.toUpperCase()}</b>
					</label>
				</div>);

				/* Place info about device usage here */
				//out.push(<div key={107} className=""><>This is for TVs</></div>);

				out.push(<div key={108} className="mt-3"></div>);

				out.push(
					<div key={110} className={"form-group "+nick_cls}>
						<label htmlFor="nickname"><h3 className="lh-08 text-center responsive-text">{this.props.language.yourName.toUpperCase()}</h3></label>
						<input type="text" className="form-control col-lg-5 col-sm-5 col-6 m-auto text-center" id="nickname" onChange={(e) => this.onChange("nickname", e)} />
					</div>
				);
			}
		} else {
			return (<div className="container-fluid d-flex justify-content-center h-index">
				<div className="logo-container"><img src={logoIndexImage} alt="Logo" className="img-fluid logo mb-2" /></div>
				<div className="col-xl-6 col-lg-7 col-md-9 col-sm-10 col-11 my-auto box box-shadow box-padding text-center ">
					Invalid Game Mode
				</div>
			</div>);
		}

		return(
			<div className="container-fluid justify-content-center h-index">
				<Ongoing />
				<div className="logo-container"><img src={logoIndexImage} alt="Logo" className="img-fluid logo mb-2" /></div>
				<div className="row h-100">
					<div className="col-xl-6 col-lg-7 col-md-9 col-sm-10 col-11 my-auto box box-shadow box-padding text-center container-padding mx-auto">
						<div className="t-l">
							<Link to="/create"><i className="fas fa-arrow-left"></i> {this.props.language.back.toUpperCase()}</Link>
						</div>

						{gameModePicked}
						<div className="row justify-content-center">
							<div className="col-lg-12 col-sm-12">
								{out}
								<div className="new-settings mx-auto justify-content-center">

									<div className="row justify-content-center responsive-text pb-2">{this.props.language.questionsLanguage.toUpperCase()}</div>
									<div className="pills-language-tab">{language}</div>
									<div className="row">&nbsp;</div>
									<div className="row justify-content-center responsive-text pb-2">{this.props.language.categories.toUpperCase()}</div>
									<div className="pills-cat-tab">{categories}</div>
								</div>
								<br />
								<button className="btn btn-custom btn-xl" type="button" onClick={this.onCreate} disabled={this.props.create.isLoading}>{this.props.language.createLobby}</button>
								<UIError message={this.props.create.error} />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		create: state.page.create,
		settings: state.settings,
		modes: state.ui.language.modes,
		categories: state.ui.language.categories,

		language: state.ui.language.create,
		languageid: state.ui.language.id,
		images: state.ui.language.images,
	}
}

export default connect(mapStateToProps)(Create);
