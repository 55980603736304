import React from 'react'
import AppActions from '../actions/app-actions.js';
import AppStore from '../stores/app-store.js';
import AppUtils from '../utils/app-utils';
import logoIndexImage from '../images/logo.svg';
import { Link } from 'react-router-dom';
import UIError from './app-ui-error';
import { connect } from 'react-redux';

import '../css/lobby-done.css';

let STATE_NONE = 0, STATE_INTRO = 1, STATE_WINNER = 2, STATE_ALL_HIDE = 3;
let STATE_NONE_TIMER = 300, STATE_INTRO_TIMER = 1500, STATE_WINNER_TIMER = 3500, STATE_ALL_HIDE_TIMER = 500;

class LobbyDoneTest extends React.Component {
	constructor(props) {
		super(props);

		this.state = { hasClickedRematch: false, currentState: STATE_NONE, intervalHandle: null };
		this.onStateChange = this.onStateChange.bind(this);
		this.onLeave = this.onLeave.bind(this);
		this.onRematch = this.onRematch.bind(this);
		/* Child ref */
		this.childRef = React.createRef();
	}

	componentDidMount() {
		AppUtils.setBackgroundImage();
		this.setState({intervalHandle: setTimeout(this.onStateChange, STATE_NONE_TIMER), currentState: STATE_NONE});
	}
	componentWillUnmount() {
		AppUtils.setBackgroundLinear();
		if(this.state.intervalHandle !== null) clearTimeout(this.state.intervalHandle);
	}

	onStateChange() {
		console.log("Current state is", this.state.currentState);
		console.log("Changing to", (this.state.currentState+1));
		clearTimeout(this.state.intervalHandle);
		//this.setState({intervalHandle: null});
		console.log("Doing a switch on", (this.state.currentState+1));
		switch((this.state.currentState+1)) {
			case STATE_INTRO:
				this.setState({intervalHandle: setTimeout(this.onStateChange, STATE_INTRO_TIMER) });
				break;
			case STATE_WINNER:
				this.setState({intervalHandle: setTimeout(this.onStateChange, STATE_WINNER_TIMER) });
				break;
			case STATE_ALL_HIDE:
				this.setState({intervalHandle: setTimeout(this.onStateChange, STATE_ALL_HIDE_TIMER) });
				break;
			default:
				break;
		}
		this.setState({currentState: this.state.currentState+1});
	}

	onLeave() {
		AppStore.dispatch(AppActions.LeaveLobby());
	}

	onRematch() {
		if(this.props.lobby.admin)
			AppStore.dispatch(AppActions.RematchLobby({code: this.props.lobby.code}));
		else {
			this.setState({ hasClickedRematch: true });
		}
	}

	render() {
		let winners = [];
		let winners_points = -1;
		let done_players = [];
		let out = [];
		
		let tmp = [].concat(this.props.lobby.done.players).sort((a, b) => a.score < b.score ? 1 : -1);
		tmp.forEach((v, i) => {
			let cls_hl = "";
			if(v.nickname.toLowerCase() === this.props.lobby.nickname.toLowerCase()) cls_hl = "highlight";
			if(winners_points === -1) winners_points = v.score;

			if(v.score >= winners_points) {
				if(winners.length > 0) winners.push(<span key={i+"-space"}>, </span>);
				winners.push(<span key={i} className={cls_hl}>{v.nickname}</span>);
			} else {
				done_players.push(<tr key={i} className={cls_hl}><td>{v.nickname}</td><td>{v.score}</td></tr>);
			}
		});

		let tmp_out = [];
		if(this.state.currentState === STATE_NONE || this.state.currentState === STATE_INTRO || this.state.currentState === STATE_WINNER) {
			let introClass = "";
			if(this.state.currentState !== STATE_NONE) introClass = "moes-fade-show";
			let winnerClass = "";
			if(this.state.currentState === STATE_WINNER) {
				winnerClass = "moes-fade-show";
			}
			tmp_out.push(<div key={1000} className="find-me">
				<div>
					<div className={"moes-hide "+introClass}>
						<h2 className="lh-11 mb-4 c-o" >{this.props.language.theWinnerIs}</h2>
						
					</div>
					<div className={"moes-hide "+winnerClass}>
						<img src="https://cdn.moes.beer/images/lobby/trophy_gold.png?v=6" alt="Winner Trophy" />
						<div className="responsive-text-big">{winners}&nbsp;</div>
					</div>
				</div>
			</div>);
		}

		/* If host, show Moes logo - if player, show social medias */
		let logoOrSocial = "";

		if(this.state.currentState === STATE_NONE || this.state.currentState === STATE_INTRO || this.state.currentState === STATE_WINNER) {
			return(<div className="container-fluid text-center my-auto h-index">
				<div className="logo-container">{logoOrSocial}</div>
				<div style={{minHeight: '100%', display: 'flex', alignItems: 'center'}}>
					<div className="container text-center my-auto">
						{tmp_out}
					</div>
				</div>
			</div>);
		}

		logoOrSocial = (<img src={logoIndexImage} alt="Logo" className="img-fluid logo mb-2" />);
		if(this.props.lobby.playing) {
			logoOrSocial = (<span>
				<div style={{color: "#6e707b", textAlign: "left"}}>{this.props.language.followUs}</div>
				<Link to={{ pathname: "https://facebook.com/moesbeergame"}} target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook fa-2x pr-3"></i></Link>
				<Link to={{ pathname: "https://instagram.com/moesbeergame"}} target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram fa-2x"></i></Link>
			</span>);
		}

		if(done_players.length > 0) {
			out.push(
				<div key={0} className="row justify-content-sm-center">
					<div className="col-lg-10 col-sm-10 pt-3">
						<div className="table-done-container mx-auto">
							<table className="table table-border mx-auto c-w table-done">
								<thead className="">
									<tr>
										<th className="c-o" scope="col">{this.props.language.nickname.toUpperCase()}</th>
										<th className="c-o" scope="col">{this.props.language.score.toUpperCase()}</th>
									</tr>
								</thead>
								<tbody>
									{done_players}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			);
		}
		
		let admin = [];
		if(this.props.lobby.admin || !this.state.hasClickedRematch) {
			admin.push(<div key={100} className="row justify-content-sm-center mt-3">
				<div className="col-sm-6">
					<button className="btn btn-xl btn-question btn-question-green" onClick={this.onRematch}>{this.props.language.playAgain}</button>
				</div>
			</div>);
		} else {
			admin.push(<div key={100} className="row justify-content-sm-center mt-3">
				<div className="col-sm-6">
					<button className="btn btn-xl btn-question btn-disabled" disabled={1} onClick={this.onRematch}>{this.props.language.playAgainWaiting}</button>
				</div>
			</div>);
		}
	
		return(
			<div className="container-fluid text-center my-auto pt-5 h-index">
				<div className="logo-container">{logoOrSocial}</div>
				
				<h2 className="lh-11 pt-3 mt-4 mb-4 c-o">{this.props.language.thanksForPlaying}</h2>
				<div className="row justify-content-sm-center">
					<div className="col-lg-10 col-sm-10 pt-2"><img src="https://cdn.moes.beer/images/lobby/trophy_gold.png?v=6" alt="Winner Trophy" /></div>
				</div>
				<div className="row justify-content-sm-center">
					<div className="col-lg-10 col-sm-10">
						<div className="responsive-text-big">{winners}</div>
						<div>{winners_points} {this.props.language.points}</div>
					</div>
				</div>

				{out}

				<div className="row justify-content-sm-center mt-3">
					<div className="col-sm-6">
						<UIError message={this.props.lobby.error} />
					</div>
				</div>

				{admin}
				<div className="row justify-content-sm-center mt-3">
					<div className="col-sm-6 pb-4">
						<button className="btn btn-xl btn-question btn-question-red" onClick={this.onLeave}>{this.props.language.leaveLobby}</button>
					</div>
				</div>
				
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		lobby: state.page.lobby,
		language: state.ui.language.lobby.done,
	}
}

export default connect(mapStateToProps)(LobbyDoneTest);