import React from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Language from './app-language';
import AppUtils from '../utils/app-utils';

class Index extends React.Component {

	render() {
		let intro = [];
		let outro = [];

		this.props.language.intro.forEach( (v, i) => {
			intro.push(<div key={i}>{v}</div>);
		});
		this.props.language.outro.forEach( (v, i) => {
			outro.push(<div key={i}>{v}</div>);
		});

		return(
			<div className="container-fluid">
			<div className="row pt-xl-5">&nbsp;</div>
			
			<div className="row pt-xl-5 ml-2 mr-2 ml-xl-5 mr-xl-5">
				<div className="col-xl-6 col-lg-12 pl-xl-5 text-center text-xl-left" >
					<Language />
					<div className="pt-3 pt-xl-5">
						<h1 className="ph-fc pb-3 ph-fs">{AppUtils.replaceNickname(this.props.language.title)}</h1>
					</div>

					<div className="d-block d-xl-none my-auto">
						<img className="img-fluid" src="https://cdn.moes.beer/images/placeholder/phones_small.png" alt="Game Preview" />
					</div>

					<div className="lead ph-fc text-left text-md-center text-xl-left">
						{intro}
					</div>

					<div className="lead ph-fc text-left text-md-center text-xl-left">
						{outro}
					</div>
					<div className="lead ph-fc text-left text-md-center text-xl-left">
						{this.props.language.socialMedia}
						<div className="p-1">
							<Link to={{ pathname: "https://facebook.com/moesbeergame"}} target="_blank" rel="noopener noreferrer" style={{color: '#734e9a'}}><i className="fab fa-facebook fa-2x"></i></Link>
							&nbsp;&nbsp;
							<Link to={{ pathname: "https://instagram.com/moesbeergame"}} target="_blank" rel="noopener noreferrer"  style={{color: '#734e9a'}}><i className="fab fa-instagram fa-2x"></i></Link>
						</div>
					</div>
					<div>&nbsp;</div>
					<div>
						<Link to="/play">
							<button className="btn-placeholder ph-fc">{this.props.language.play}</button>
						</Link>
					</div>
				</div>
				<div className="col-xl-6 col-lg-12 col-md-12 text-center">
					<div className="d-none d-xl-block my-auto">
						<img className="img-fluid" src="https://cdn.moes.beer/images/placeholder/phones.png" alt="Game Preview" />
					</div>
				</div>
			</div>
		</div>
		);
	}
}
function mapStateToProps(state) {
	return {
		language: state.ui.language.index,
	}
}
export default connect(mapStateToProps)(Index);
