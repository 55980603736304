import React from 'react'

import UITimer from './app-ui-timer';
import { connect } from 'react-redux';

class LobbyStreak extends React.Component {
	constructor(props) {
		super(props);

		this.state = { resetTimer: false, showQuestion: true }
	}

	componentDidUpdate(prevProps, prevState, ss) {
		if(this.props.lobby.state !== prevProps.lobby.state) {
			this.setState({resetTimer: true}, function() {
				this.setState({resetTimer: false})
			});
		}

	}

	render() {
		return(<div className="container text-center my-auto">
			<h2 className="lh-11 mt-4 mb-4">STREAK!</h2>
			<UITimer seconds={this.props.lobby.timeleft} reset={this.state.resetTimer} />
			<div className="row">&nbsp;</div>
		</div>);
	}
}

function mapStateToProps(state) {
	return {
		lobby: state.page.lobby,
	}
}

export default connect(mapStateToProps)(LobbyStreak);
