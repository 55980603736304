import React from 'react'

import AppStore from '../stores/app-store';
import AppActions from '../actions/app-actions';
import AppConstants from '../constants/app-constants';
import AppUtils from '../utils/app-utils';

import UITimer from './app-ui-timer';

import { connect } from 'react-redux';

import '../css/pulse.css';

let WRONG_IMAGE = "https://cdn.moes.beer/images/lobby/drink.png";
let CORRECT_IMAGE = "https://cdn.moes.beer/images/lobby/correct.png";
let MOST_LIKELY_IMAGE = "https://cdn.moes.beer/images/lobby/most-likely.png";

class LobbyDrink extends React.Component {
	constructor(props) {
		super(props);

		this.state = { resetTimer: false, showTable: false }
		this.onToggleTable = this.onToggleTable.bind(this);
	}

	componentDidUpdate(prevProps, prevState, ss) {
		if(this.props.lobby.state !== prevProps.lobby.state) {
			this.setState({resetTimer: true}, function() {
				this.setState({resetTimer: false})
			});
		}
	}

	componentDidMount() {
		AppUtils.setBackgroundImage();
		AppUtils.vibrate(500);
	}
	componentWillUnmount() {
		AppUtils.setBackgroundLinear();
		
		document.body.classList.remove('body-answer');
		document.body.classList.remove('body-answer-wrong');
	}

	onReady() {
		AppStore.dispatch(AppActions.Ready({}));
	}
	onToggleTable() {
		this.setState({ showTable: !this.state.showTable });
	}

	render() {
		document.body.classList.remove('body-answer');
		document.body.classList.remove('body-answer-wrong');

		/* Sets text to 'Correct Answer' and defaults to 'DRAW' */
		let correct_answer_text = this.props.language.correctAnswer.toUpperCase();
		let correct_answer = this.props.language.draw;
		if(this.props.lobby.drink.correct_option && this.props.lobby.drink.correct_option.length > 0) {
			correct_answer = this.props.lobby.drink.correct_option;
		}

		/* If most likely, the answer is in the .extra variable */
		/* correct_answer is set at the end (it also changes status_image and status_text) */
		/* This is so we can still maintain the options for what everyone voted at */

		let extra_info = "";
		/* Incase this question has any extra info, that's good to know */
		if(this.props.lobby.drink.question_extra.length > 0) {
			extra_info = (<div key="extra" className="row">
				<div className="col-sm-12 text-center mt-2 mb-2">
					<i><h3 className="responsive-text">{this.props.lobby.drink.question_extra}</h3></i>
				</div>
			</div>);
		}

		let table_options = [[], [], [], [], []];

		let drink_players = [];
		if(this.props.lobby.drink.wrong.length > 0) {
			drink_players = [].concat(this.props.lobby.drink.wrong).sort((a, b) => a.option > b.option ? 1 : -1);
		}

		let correct_class = "";
		let status_image = CORRECT_IMAGE;
		let status_text = this.props.language.answerCorrect;

		/* Table for correct answers */
		//if(this.props.lobby.current_question.category !== AppConstants.CATEGORY_MOST_LIKELY) {
			if(this.props.lobby.drink.correct.length > 0) {
				let cid = (this.props.lobby.drink.correct_option_id-1);
				AppUtils.log("[Drinking] Cid has been set to", cid);
				AppUtils.log("[Drinking] Lobby is", this.props.lobby);

				let row_tmp = [];
				this.props.lobby.drink.correct.forEach( (v, i) => {
					if(row_tmp.length === 2) {
						table_options[cid].push(<tr key={cid+"-"+i} className="drink-users">{row_tmp}</tr>);
						row_tmp = [];
					}

					row_tmp.push(<td key={i}>{v}</td>);
				});

				if(row_tmp.length > 0) table_options[cid].push(<tr key={cid+"-last"} className="drink-users">{row_tmp}</tr>);
			}
		//}
		
		/* Table creation for losers table */
		if(drink_players.length > 0) {
			let last_option = -100;

			/* Loop each player */
			let row_tmp = [];
			drink_players.forEach( (v, i) => {

				/* Save status for my guess, and perhaps highlight? */
				if(v.nickname.toLowerCase() === this.props.lobby.nickname.toLowerCase()) {
					/* Change text */
					correct_class = "-wrong";
					status_image = WRONG_IMAGE;
					status_text = this.props.language.answerIncorrect;
				}

				/* Add option if it has changed */
				if(/*this.props.lobby.current_question.category !== AppConstants.CATEGORY_MOST_LIKELY && */v.option !== last_option) {
					if(v.option === 0) {
						/* Someone forgot to answer..? */
						return;
					}
					if(row_tmp.length > 0) table_options[(last_option-1)].push(<tr key={last_option+"-last"} className="drink-users">{row_tmp}</tr>);
					row_tmp = [];

					last_option = v.option;
				}

				if(row_tmp.length === 2) {
					table_options[(v.option-1)].push(<tr key={v.option+"-"+i} className="drink-users">{row_tmp}</tr>);
					row_tmp = [];
				}
				row_tmp.push(<td key={i}>{v.nickname}</td>);



			});

			if(row_tmp.length > 0) table_options[(last_option-1)].push(<tr key={last_option+"-last"} className="drink-users">{row_tmp}</tr>);
		}

		/* Incase the server sends any extra text, display it after the answer */
		/* However, if we're playing most likely the answer will be the 'extra' variable */
		if(this.props.lobby.current_question.category === AppConstants.CATEGORY_MOST_LIKELY) {
			correct_class = "";
			status_image = MOST_LIKELY_IMAGE;
			status_text = this.props.language.answerCorrect;

			/* Update text from 'CORRECT ANSWER' to 'MOST LIKELY' */
			correct_answer_text = this.props.categories[AppConstants.CATEGORY_MOST_LIKELY].toUpperCase();
			if(this.props.lobby.drink.text && this.props.lobby.drink.text.length > 0)
				correct_answer = this.props.lobby.drink.text;

			/* Check if we're the one who needs to drink */
			if((this.props.lobby.drink.text && this.props.lobby.drink.text.length === 0) || correct_answer.toLowerCase() === this.props.lobby.nickname.toLowerCase() || this.props.lobby.drink.correct_option_id === -1) {
				correct_class = "-wrong";
				status_image = WRONG_IMAGE;
				status_text = this.props.language.answerIncorrect;
			}
		} else if(this.props.lobby.current_question.category === AppConstants.CATEGORY_PICK_SIDE && this.props.lobby.drink.text.toLowerCase() === "yatzy") {
			/*extra_info = (<div key={110} className="row">
				<div className="col-sm-12 text-center">
					<i><b>{this.props.language.yatzy}</b></i>
				</div>
			</div>);*/
			correct_answer_text = this.props.language.yatzy;
		} else if(this.props.lobby.drink.text && this.props.lobby.drink.text.length > 0) {
			extra_info = (<div key={110} className="row">
				<div className="col-sm-12 text-center mb-2 mt-2">
					<i><h3 className="responsive-text">{this.props.lobby.drink.text}</h3></i>
				</div>
			</div>);
		}

		/* Add AFK people */
		if(this.props.lobby.drink.timeout.length > 0) {
			this.props.lobby.drink.timeout.forEach( (v, i) => {
				if(v.toLowerCase() === this.props.lobby.nickname.toLowerCase()) {
					correct_class = "-wrong";
					status_image = WRONG_IMAGE;
					status_text = this.props.language.answerIncorrect;
				}
			});
		}

		/* Ready text */
		let ready = "";
		if(this.props.lobby.playing) {
			if(!this.props.lobby.ready) ready = (<button className="btn btn-question btn-xl btn-ready" onClick={this.onReady}>{this.props.language.ready}</button>);
			else {
				if(this.props.lobby.ready_status.length > 0) ready = (<button className="btn btn-question btn-xl btn-ready btn-disabled">{AppUtils.replaceLanguage(this.props.language.readyStatus, { "%c": this.props.lobby.ready_status })}</button>);
				else ready = (<button className="btn btn-question btn-xl btn-ready btn-disabled">{this.props.language.isReady}</button>);
			}
		}

		/* Image text (class) */
		let image_text = (<div className="row">&nbsp;</div>);
		if(this.props.lobby.playing) {
			let anim_class = "pulse"+correct_class+" 2s infinite";
			
			image_text = (<div className="p-5">
				<img src={status_image} alt={status_text} className="drink-image" style={{animation: anim_class}} />
			</div>);
		}

		if(!this.props.lobby.playing) {
			correct_class = "";
		}

		/* Add class for blinking and stuff */
		document.body.classList.add("UNUSED-body-answer"+correct_class);

		let out = [];
		if(!this.state.showTable || !this.props.lobby.playing) {
			let correct_answer_container = "";
			if(this.props.lobby.playing) {
				correct_answer_container = (<div className="m-auto flex-middle p-r">
					<span className="font-weight-bold responsive-text">{correct_answer_text}</span>
					<h2 className={"responsive-text-big answer"+correct_class}>{correct_answer}</h2>
				</div>);
			}

			out.push(<div key={100} className="row justify-content-sm-center">
				<div className="col-lg-12 col-sm-10 p-r">
					{image_text}
					{correct_answer_container}
				</div>
			</div>);
			if(this.props.lobby.playing) {
				out.push(extra_info);
			}
		}
		if(this.state.showTable || !this.props.lobby.playing) {
			//let tvclass = "";
			//if(!this.props.lobby.playing) tvclass = " table-drink-stream";

			/* Show question */
			out.push(<div key={200} className="row">
				<div className="col-sm-12 text-center mt-3 mb-3">
					<h2 className="lh-11 question-size">
						{AppUtils.replaceNickname(this.props.lobby.current_question.question)}
					</h2>
				</div>
			</div>);

			if(!this.props.lobby.playing) {
				out.push(extra_info);
			}

			AppUtils.log("[Drinking] Correct option ID is", this.props.lobby.current_question.correct_option_id);
			AppUtils.log("[Drinking] Table options are:", table_options);

			let oc = ["", "", "", "", ""];
			if(oc[(this.props.lobby.drink.correct_option_id-1)] !== undefined) oc[(this.props.lobby.drink.correct_option_id-1)] = " drink-option-correct";

			let tmp = [];
			for(let f = 1; f <= 5; f++) {
				if(AppUtils.getArrayWithId(this.props.lobby.current_question.options, f) !== undefined) {
					let option_name = AppUtils.getArrayWithId(this.props.lobby.current_question.options, f);
					tmp.push(<div className="col-xl-6" key={f}>
					<div className="table-responsive">
						<table className="table table-drink">
							<thead>
								<tr><th colSpan="2" className={"drink-option"+oc[(f-1)]}>{option_name.value}</th></tr>
							</thead>
							<tbody>
								{table_options[(f-1)]}
							</tbody>
						</table>
					</div>
				</div>
					);
				}
				if(tmp.length === 2) {
					out.push(<div key={201+"-"+f} className="row">{tmp}</div>);
					tmp = [];
				}
			}
			if(tmp.length > 0) out.push(<div key={201+"-last"} className="row">{tmp}</div>);

		}
		
		let voteText = this.props.language.showVotes;
		if(this.state.showTable) voteText = this.props.language.hideVotes;
		
		let showHideButton = "";
		if(this.props.lobby.playing) showHideButton = <div><button className="btn btn-question btn-xl btn-ready mb-3" onClick={this.onToggleTable}>{voteText}</button></div>;
		return(<div className="container text-center my-auto pt-3">
			<UITimer seconds={this.props.lobby.timeleft} reset={this.state.resetTimer} message={AppUtils.replaceLanguage(this.props.language.questionsDone, { "%q": this.props.lobby.question, "%t": this.props.lobby.number_questions })} />

			{out}

			<div className="row">&nbsp;</div>
			{showHideButton}
			<div>{ready}</div>
		</div>);
	}
}

function mapStateToProps(state) {
	return {
		categories: state.ui.language.categories,
		lobby: state.page.lobby,
		language: state.ui.language.lobby.drink,
	}
}

export default connect(mapStateToProps)(LobbyDrink);
