import React from 'react'
import { connect } from 'react-redux';

import AppConstants from '../constants/app-constants';
import AppStore from '../stores/app-store';
import AppActions from '../actions/app-actions';

class LanguagePicker extends React.Component {
	constructor(props) {
		super(props);
		this.setLanguage = this.setLanguage.bind(this);
	}

	setLanguage(v) {
		AppStore.dispatch(AppActions.LanguageSet({language: v.short}));
	}

	render() {
		if(this.props.lobby.code.length > 0 && (
			this.props.lobby.state === AppConstants.STATE_QUESTION
			||
			this.props.lobby.state === AppConstants.STATE_LOBBY
			||
			this.props.lobby.state === AppConstants.STATE_DRINK
			||
			this.props.lobby.state === AppConstants.STATE_STARTING
			||
			this.props.lobby.state === AppConstants.STATE_DONE
		)) return(<React.Fragment></React.Fragment>);
		let langs = [];

		this.props.ui.languageAvailable.forEach( (v, i) => {
			langs.push(<div key={i} className="language" onClick={() => this.setLanguage(v)}>{v.name}</div>);
		});

		return(
			<div>
				<span className="dropdown-language" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					<img src="https://cdn.moes.beer/images/placeholder/globe.png?v=1" alt="Change Language" />
					<span className="dropdown-language-selected">{this.props.ui.language.short}</span>
					<span className="dropdown-language-arrow"></span>
					
					
				</span>
				<div className="dropdown-menu dropdown-menu-custom" aria-labelledby="dropdownMenuButton">
					{langs}
				</div>
			</div>
		);

	}
}

function mapStateToProps(state) {
	return {
		lobby: state.page.lobby,
		ui: state.ui,
	}
}

export default connect(mapStateToProps)(LanguagePicker);
