import AppStore from '../stores/app-store.js';
import Cookies from 'universal-cookie';
import parse from 'html-react-parser';

const cookies = new Cookies();

var AppUtils = {

	sanitizeUrl: function(url, addPath) {
		let out = url.replace("/login", "");
		if (out.length === 1 && out[0] === '/') out = "";
		out = out.replace(/\/$/, '');
		out = out.concat(addPath);
		return out;
	},
	fixUrl: function(url) {
		return url.replace(/ /g, "+");
	},

	random: function(min, max) {
		return min + (Math.random() * (max-min));
	},
	randomArray: function(a) {
		return a[Math.floor(Math.random() * a.length)];
	},

	/* Helper functions */
	getArrayWithId: function(a, id) {
		for(var i in a) {
			if(a[i].id === id) return a[i];
		}
		return undefined;
	},
	categoryHasMode: function(a, c, m) {
		for(var i in a) {
			if(a[i].category === c && a[i].mode === m) return true;
		}
		return false;
	},
	getCategoriesForMode: function(a, m) {
		let out = [];
		for(var i in a) {
			if(a[i].mode === m) out.push(a[i].category);
		}
		return out;
	},

	isLoggedIn: function() {
		let state = AppStore.getState();
		return (state.auth.user.id > 0);
	},
	getCookie: function(l) {
		/* Get Cookie for lobby: l */
		return cookies.get("MOES_"+l, { path: '/'});
	},
	saveCookie: function(lobby, code) {
		cookies.set("MOES_"+lobby, code, { path: '/', maxAge: 3600, sameSite: 'None', secure: true });
	},
	removeCookie: function(lobby) {
		cookies.remove("MOES_"+lobby, { path: '/' });
	},
	getAllCookies: function() {
		return cookies.getAll();
	},

	replaceLanguage: function (str, mapObj) {
		var re = new RegExp(Object.keys(mapObj).join("|"),"gi");
		str = str.replace(re, function(matched){
			return mapObj[matched.toLowerCase()];
		});
		return str;
	},
	replaceNickname: function (str) {
		const regex = /<([^ ]*)>/g;
		return parse(str.replace(regex, "<span class='c-b'>$1</span>"));
	},
	removeBackground: function() {
		/* Remove current linear/background image */
		document.body.style.backgroundImage = "none";
		/* Reset backgroundSize */
		document.body.style.backgroundSize = "none";
	},
	setBackgroundBlack: function() {
		this.removeBackground();
		document.body.style.backgroundColor = "#201F1E";
	},
	setBackgroundImage: function() {
		this.removeBackground();
		/* Set new one */
		document.body.style.backgroundImage = "url(https://cdn.moes.beer/images/background.svg?v=2)";
		/* Set background color to match bottom of image */
		document.body.style.backgroundColor = "#5E379A";
		/* Make it cover */
		document.body.style.backgroundSize = "cover";
	},
	setBackgroundLinear: function() {
		this.removeBackground();
		/* Set linear */
		document.body.style.backgroundImage = "-webkit-linear-gradient(116.8deg, #28263E 50%, #201E33 50%)";
		/* Set background color to match linear */
		document.body.style.backgroundColor = "#28263E";
		/* Set attachment */
		document.body.style.backgroundAttachment = "fixed";
	},
	vibrate: function(x) {
		if ("vibrate" in navigator) {
			navigator.vibrate(x);
		}
	},
	log: function(...args) {
		if(process.env.REACT_APP_LOG === "true") {
			if(typeof(console) !== 'undefined') {
				console.log(...args);
			}
		}
	}
}

export default AppUtils;
