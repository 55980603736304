import React from 'react';

let TICKDELAY = 100;
class UITimer extends React.Component {
	constructor(props) {
		super(props);
		this.state = { timer: null, start: 100, current: 100, seconds: 0 };

		this.onTick = this.onTick.bind(this);
		this.stopTimer = this.stopTimer.bind(this);
	}

	componentDidMount() {
		this.setState({timer: setInterval(this.onTick, TICKDELAY), seconds: this.props.seconds, current: (this.props.seconds*10), start: (this.props.seconds*10) });
	}
	componentWillUnmount() {
		this.stopTimer();
	}
	componentDidUpdate(prevProps, prevState, ss) {
		if(prevProps.reset !== this.props.reset && this.props.reset) {
			this.stopTimer();
			this.setState({timer: setInterval(this.onTick, TICKDELAY), seconds: this.props.seconds, current: (this.props.seconds*10), start: (this.props.seconds*10) });
		}
		if(prevProps.seconds !== this.props.seconds) {
			/* Seconds have changed. We need to keep start but change current */
			this.setState({current: (this.props.seconds*10)});
		}
	}
	stopTimer() {
		if(this.state.timer !== null) {
			clearInterval(this.state.timer);
		}
	}
	onTick() {
		/* So we're ticking with TICKDELAY ms, we need to figure out how many % each tick is */
		this.setState({current: (this.state.current-1)}, function() {
			if(this.state.current < 0) {
				this.stopTimer();
			}
		});
	}

	render() {
		let proc_left = (this.state.current/this.state.start)*100;
		if(this.props.reverse !== undefined && this.props.reverse) {
			proc_left = 100-proc_left;
		}
		
		let cls = "timer-100";
		if(!this.props.reverse) {
			/*
			if(proc_left <= 10) cls = "timer-10";
			else if(proc_left <= 20) cls = "timer-20";
			else if(proc_left <= 30) cls = "timer-30";
			else if(proc_left <= 40) cls = "timer-40";
			else if(proc_left <= 50) cls = "timer-50";
			else if(proc_left <= 60) cls = "timer-60";
			else if(proc_left <= 70) cls = "timer-70";
			else if(proc_left <= 80) cls = "timer-80";
			else if(proc_left <= 90) cls = "timer-90";
			if(proc_left === 100) cls = "timer-100 no-animation";
			*/
			if(proc_left <= 25) cls = "timer-10";
			else if(proc_left <= 60) cls = "timer-60";
			else cls = "timer-90";
			if(proc_left === 100) cls = "timer-90 no-animation";
		}

		let msg = "";
		if(this.props.message) {
			msg = (<span className="justify-content-center d-flex position-absolute w-100-30" style={{lineHeight: '25px', color: '#FFF'}}>{this.props.message}</span>);
		}
		return(<div className="row pb-1">
			<div className="col-sm-12 text-center">
				<div className="progress">
					<div className={"progress-bar small-animation timer "+cls} style={{width: proc_left+"%"}} id="question-progress" role="progressbar" aria-valuenow={this.state.current}  aria-valuemin="0" aria-valuemax="100"></div>
					{msg}
				</div>
			</div>
		</div>);
	}
}

export default UITimer;
