import React from 'react'
import AppActions from '../actions/app-actions.js';
import AppConstants from '../constants/app-constants';
import AppStore from '../stores/app-store.js';
import AppUtils from '../utils/app-utils';

import UITimer from './app-ui-timer';

import { connect } from 'react-redux';


class LobbyQuestion extends React.Component {
	constructor(props) {
		super(props);

		this.state = { resetTimer: false, showQuestion: true }
		this.onToggleShowQuestion = this.onToggleShowQuestion.bind(this);
	}

	componentDidUpdate(prevProps, prevState, ss) {
		if(this.props.lobby.state !== prevProps.lobby.state) {
			this.setState({resetTimer: true}, function() {
				this.setState({resetTimer: false})
			});
		}

		if(prevProps.lobby.state !== AppConstants.STATE_QUESTION && this.props.lobby.state === AppConstants.STATE_QUESTION) {
			AppStore.dispatch(AppActions.GameResetAnswer({}));
		}
		if(prevProps.lobby.show_question !== this.props.lobby.show_question) {
			this.setState({ showQuestion: this.props.lobby.show_question });
		}

	}

	componentDidMount() {
		this.setState({ showQuestion: this.props.lobby.show_question });
		AppUtils.setBackgroundImage();
		AppUtils.vibrate(100);

	}
	componentWillUnmount() {
		AppUtils.setBackgroundLinear();
	}

	
	onAnswer(value) {
		if(!this.props.lobby.playing) return;
		if(this.props.lobby.answer > 0) return;
		AppStore.dispatch(AppActions.GameAnswer({answer: value}));
	}
	onToggleShowQuestion() {
		AppStore.dispatch(AppActions.ShowQuestion({ show_question: !this.state.showQuestion }));
		this.setState({ show_question: !this.state.showQuestion });
	}

	render() {
		let question = "";
		if(this.state.showQuestion) question = (<div className="row justify-content-sm-center"><div className="col-lg-12 col-sm-12"><h2 className="lh-11 question-size mt-3">{AppUtils.replaceNickname(this.props.lobby.current_question.question)}</h2></div></div>);
		let options = [];
		this.props.lobby.current_question.options.forEach( (v, i) => {
			let cls = "";
			let canClick = true;
			if(this.props.lobby.answer > 0) {
				if(this.props.lobby.answer === v.id) cls = "btn-question-active";
				else cls = "btn-disabled";
			} else if(this.props.lobby.current_question.category === AppConstants.CATEGORY_MOST_LIKELY && this.props.lobby.nickname.toLowerCase() === v.value.toLowerCase()) {
				canClick = false;
				cls = "btn-disabled";
			}
			if(!canClick) {
				options.push(<div key={v.id} className="col-sm-12 col-lg-6 col-md-12 mb-3">
					<button type="button" className={"btn btn-block btn-lg btn-size btn-question "+cls}>{v.value}</button>
				</div>);
			} else {
				options.push(<div key={v.id} className="col-sm-12 col-lg-6 col-md-12 mb-3">
					<button type="button" className={"btn btn-block btn-lg btn-size btn-question "+cls} onClick={() => this.onAnswer(v.id)}>{v.value}</button>
				</div>);
			}
		});

		let first = "";
		if(this.props.lobby.playing) {
			if(this.props.lobby.question <= 3) {
				if(this.state.showQuestion) first = this.props.language.pressTimerToHide;
				else first = this.props.language.pressTimerToShow;
				//first = (<div className=""><i>{this.props.language.pressTimerToShow}</i></div>);
			}
			return(<div className="container text-center my-auto pt-3">
				<span onClick={this.onToggleShowQuestion} ><UITimer seconds={this.props.lobby.timeleft} reset={this.state.resetTimer} message={first} /></span>
				{/*first*/}
				{question}
				<div className="row">&nbsp;</div>
				<div className="row justify-content-sm-center">
					{options}
				</div>
			</div>);
		}
		return(<div style={{minHeight: '100%', display: 'flex', alignItems: 'center'}}>
			<div className="container text-center my-auto">
				<span onClick={this.onToggleShowQuestion}><UITimer seconds={this.props.lobby.timeleft} reset={this.state.resetTimer} /></span>
				<div className="row">&nbsp;</div>
				<div className="row justify-content-sm-center">
					{question}
				</div>
			</div>
		</div>);
	}
}

function mapStateToProps(state) {
	return {
		lobby: state.page.lobby,
		language: state.ui.language.lobby.question,
	}
}

export default connect(mapStateToProps)(LobbyQuestion);
