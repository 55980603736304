const LanguageSwedish = {
	/* ID must match database */
	id: 2,
	name: 'svenska',
	short: 'SV',
	flag: 'https://moes.beer/images/languages/swedish.svg',

	categories: {
		1: "Frågor om deltagarna",
		2: "Random Frågor",
		4: "Välj en sida",
		5: "Vem av Oss",
	},

	/* Index */
	index: {
		title: "De klassiska <ölspelen> samlade online",

		intro: [
			"Vilken film beskriver ditt sexliv?",
			"Vem av oss skulle svara i telefonen medans hen har sex?",
			"Detta är typen av frågor du kommer behöva besvara när du spelar moes Beer."
		],

		outro: [
			"Moes.beer är ett gratis onlinespel där varje deltagare ansluter med sin telefon för att svara på frågor.",
			"För optimal upplevelse så streamas frågorna till en TV på festen,",
			"antingen via webbläsaren i en SmartTV eller en dator kopplad till TVn.",
			"Annars så finns stöd för enbart kunna spela via mobiltelefoner."
		],
		
		socialMedia: "Följ oss på våra sociala medier!",

		play: "Spela",
	},

	/* Play */
	play: {
		create: "Starta spel",
		join: "Gå med i spel",
		help: "Hjälp",
		thanksForVisiting: "Tack för besöket, vi hoppas du har roligt att spela Moes.Beer!",
		followSocialMedias: "Vi hade uppskattat det om du följer oss på %i och sprider ordet! 🍺",
	},

	/* Ongoing games */
	ongoing: {
		ongoing: "Pågående spel",
		info: "",
	},

	fullscreen: {
		question: "Vill du spela i fullskärm?",
		yes: "Ja",
		no: "Nej"
	},

	/* Create */
	create: {
		back: "Bakåt",
		next: "Nästa",
		gameMode: "Välj ett Spel Läge",
		gameModeStandard: "Standard",
		gameModeSpeaker: "Läs Högt",
		selectMode: "Vänligen välj ett läge för mer information",
		deviceFor: "Denna enhet används till att",
		hosting: "Visa Frågor",
		playing: "Svara på Frågor",
		yourName: "Ditt namn",
		questionsLanguage: "Språk på Frågor",
		categories: "Kategorier",
		createLobby: "Skapa Lobby",

		modesInfo: {
			1: [
				"Alla måste delta med sin egen telefon.",
				"De kommer använda sin telefon för att svara på frågorna.",
				" ",
				"Välj denna om du tänker visa frågorna på en TV (SmartTV med webbläsare eller dator kopplad), eller delta själv."
			],
			2: [
				"Ingen kommer att kunna gå med i denna lobby.",
				"Du kommer behöva läsa upp frågorna högt.",
				" ",
				"Välj denna om du vill spela Vem av Oss eller Välj en sida."
			]
		}
	},

	/* Join */
	join: {
		back: "Bakåt",
		yourName: "Namn",
		lobbyCode: "Kod",
		joinLobby: "Gå med i Lobby",
	},

	/* Help */
	help: {
		back: "Bakåt",
		header: "Hjälp",
		howItLooks: "Hur det ser ut",
		categories: "Kategorier",
		aboutUs: "Om Oss",
		otherInfo: "Annan information",

		helpContent: [
			"Vilken film beskriver ditt sexliv? Vem av oss skulle svara i telefonen medans hen har sex?",
			"Detta är typen av frågor du kommer behöva besvara när du spelar moes Beer.",
		],
		aboutUsContent: [
			"Många av dessa frågor är skapade utav våra medlemmar.",
			"Vi tar inget ansvar för varifrån eller från vem som våra användare fått inspiration ifrån.",
			"Vi gör vårt bästa för att gå igenom alla frågor och neka, eller modifiera, de som innehåller grovt språk.",
			"Vi kan inte hållas ansvariga för frågorna som våra användare tillhandahållit.",
			"Har du frågor eller spelfrågor, feedback, idéer eller är du bara arg? Kontakta oss genom att skicka ett mail till support@moes.beer."
		],
		categoriesContent: {
			"1": ["I denna kategori så kommer en slumpmässig person få en fråga.", "Det hen svarar kommer att bli det korrekta svaret - hur bra känner du de andra på festen?"],
			"2": ["Är det olagligt att döda Jultomten i Kanada? Är korsordet en yngre uppfinning än flygplanet?", "I denna kategori gäller det att kunna onödig fakta för att briljera!"],
			"4": ["Vara osynlig eller kunna flyga? Ananas på en pizza, ris eller ros?", "Här väljer ni sida och den sida med flest val vinner - andra sidan dricker!"],
			"5": ["Vem av oss skulle kunna dumpa någon via SMS? Vem av oss kommer spy ikväll?", "Upp till fyra slumpmässiga personer som svar, dags att bli (o)vänner."]
		},
		otherInfoContent: [
			"Cookies",
			"Vi använder oss av cookies på vår hemsida för att förbättra din upplevelse.",
			"Moes.Beer använder tredjepartcookies i form av analyscookies och annonscookies. Genom detta lär vi känna dig lite bättre genom att samla in information om hur du som användare interagerar med vår hemsida.",
			"Om du inte vill acceptera cookies så är det möjligt att ställa in din webbläsare så att Cookiesfilerna inte laddas ned på din enhet.",
			" ",
			"Facebook-pixel",
			"Vi använder oss av Facebook-pixeln. Detta för att kunna visa annonser på Facebook som passar dig lite bättre, förutsatt att du är en Facebookanvändare och har gett Facebook tillåtelse till detta.",
			"Önskar du inte ge Facebook tillåtelse till detta kan du ta bort ditt godkännande via ditt Facebook-konto",
		],
	},

	/* Speaker mode */
	speaker: {
		noQuestionsFound: "Inga frågor funna. Om du uppdaterar denna sida så avslutas tyvärr omgången.",
		options: "Denna fråga har följande val:",
		personLeftOfYou: "personen till vänster om dig",
		showAnswer: "Visa svar",
		back: "Bakåt",
		next: "Nästa",
		create: "Starta en ny lobby",
		roundFinished: "Runda avklarad! Om du vill spela igen, skapa en ny lobby.",
		tips: [
			"Tips: På frågor med sant/falskt så är det smart att be folk räcka upp en hand om de tror frågan är sann.",
			"Samt: Om du tycker BAKÅT på din telefon/tablet/webbläsare så avbryts denna lobby. Vänligen använd knapparna.",
		],
	},

	/* Lobby */
	lobby: {
		joinAt: "Gå med @ https://moes.beer",
		waitingOnConnection: "Väntar på anslutning mot spelservern...",
		waitingOnServer: "Väntar på att spelservern skall verifiera din rättighet till lobbyn.",
		noCookie: "Du är inte med i denna lobby. Vänligen gå med i den igen.",
		lobbyCode: "Spel Kod",
		qrCode: "QR kod",
		leaveLobby: "Lämna Lobby",
		invalidGameState: "Felakig status på lobbyn",

		players: "%p Spelare",
		start: "Starta Spel",
		back: "Bakåt",

		/* Starting */
		starting: {
			gameStarting: "Spelet börjar!",
			tips: [
				"Ett korrekt svar är lika med ett poäng",
				"Bestäm hur många klunkar varje inkorrekt svar är!"
			]
		},

		/* Question page */
		question: {
			pressTimerToHide: "Tryck här för att gömma frågan",
			pressTimerToShow: "Tryck här för att visa frågan",
		},

		/* Drink */
		drink: {
			correctAnswer: "Rätt Svar",
			draw: "Lika",
			yatzy: "YATZY! Alla valde samma sida - alla dricker!",
			missingOption: "Saknar val/Ingen svar",
			afk: "AFK/Ingen Svar",
			none: "Ingen",

			showVotes: "Visa röster",
			hideVotes: "Göm röster",

			answerCorrect: "Du svarade RÄTT!",
			answerIncorrect: "Du svarade FEL!",

			ready: "Redo",
			isReady: "Du är redo",
			readyStatus: "%c spelare redo",
			questionsDone: "Fråga %q av %t klar"
		},

		advertise: {
			advertisement: "Reklam",
		},
		
		/* Done */
		done: {
			followUs: "Följ oss!",
			theWinnerIs: "Vinnaren är...",
			thanksForPlaying: "Tack för att ni spelat moes.Beer!",
			points: "Poäng",
			nickname: "Namn",
			score: "Poäng",
			leaveLobby: "Lämna",
			playAgain: "Spela Igen",
			playAgainWaiting: "Väntar på Admin"
		},
	},

	/* Server status */
	serverStatus: {
		idle: "Kunde ej ansluta. Uppdatera detta fönster för att försöka igen.",
		connecting: "Ansluter...",
		offline: "Kunde ej ansluta. Försöker återansluta.",
		unknown: "Okänt serverfel. Vänligen uppdatera detta fönster.",
		disconnected: "Du har blivit nerkopplad från servern: %e",
	},

	/* Errors */
	errors: {
		0: "",
		10: "Ogiltig parameter",
		50: "Saknar anslutning mot spelservern",

		100: "Ogiltig lobby",
		101: "Du är inte host",
		102: "Du är redan i en lobby",
		103: "Inte nog med spelare",
		104: "Lobby är i ett felaktigt stadie",
		105: "Du har blivit utsparkad",
		106: "Lobbyn har redan börjat",

		300: "Saknar enhet",
		301: "Ogiltigt spelläge",
		302: "Misslyckades med att starta spelet. Kolla inställningarna.",

		400: "För långt namn",
		401: "Namn finns redan i lobbyn",
		402: "Ogiltigt namn (endast a-z tillåtet)",

		500: "Du är inte autentiserad",
		501: "Du är inte med i någon lobby",
		502: "Du är redan redo",
		503: "Du är inte host",

		600: "Ingen kaka funnen för denna lobby",

		700: "Ogiltigt svar",
		701: "Du kan inte svara just nu",

		801: "Nyare anslutning funnen, dubbelkolla att du endast har en tab öppen",
	}
};

export default LanguageSwedish;